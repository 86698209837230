import React, { useEffect, useState } from "react";
import { Popover, Button, Avatar, Text } from "rizzui";
import { getAuthenticatedUser, logOutAuthenticatedUser } from "../Storage/Storage";
import { HitApi } from "../Store/Action/Api/ApiAction";
import { searchUser } from "../Constant/Api/Api";

export default function HeaderPopup() {
  const [userData, setUserData] = useState(null);

  const user = getAuthenticatedUser();

  const loadData = () => {
    let json = {
      page: 1,
      limit: 1000,
      search: {
        _id: user?.userId,
      },
    };
    HitApi(json, searchUser).then((result) => {
      setUserData(result?.content?.[0]);
    });
  };

  useEffect(() => {
    if (userData == null) {
      loadData();
    }
  }, [userData]);

  const handleLogOut = () => {
    logOutAuthenticatedUser();
    window.location.reload();
  };

  const getInitials = () => {
    if (!userData) return "..."; 
    const firstInitial = userData?.firstName?.[0] || "";
    const lastInitial = userData?.lastName?.[0] || "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  return (
    <Popover enableOverlay placement="bottom-end">
      <Popover.Trigger>
        <div className="w-12 cursor-pointer">
          <Avatar initials={getInitials()} color="primary" />
        </div>
      </Popover.Trigger>
      <Popover.Content>
        {({ setOpen }) => (
          <div className="font-geist">
            <div className="mb-3 flex items-center gap-3">
              <Avatar initials={getInitials()} color="primary" />
              <div>
                {userData && (
                  <Text className="text-base font-semibold text-gray-900">
                    {userData?.firstName + " " + userData?.lastName}
                  </Text>
                )}
                <Text className="text-sm text-gray-500">{userData?.email}</Text>
              </div>
            </div>
            <div className="max-w-[240px] text-sm">
              <Text className="text-gray-700">
                {`Associated with role ${userData?.roleName}`}
              </Text>
            </div>
            <Button className="mt-4 w-full" size="sm" onClick={handleLogOut}>
              Logout
            </Button>
          </div>
        )}
      </Popover.Content>
    </Popover>
  );
}
