import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { searchGeneral } from '../../../Constant/Api/Api';
import { setGeneralMasterData } from '../../../Store/Action/master/general-master/general-master-action';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import { CompileGeneralMaster } from './promise/general-master-promise';
import { GetGeneralMasterColumns, getGeneralMasterColumns } from './general-column';
import PageHeader from '../../../shared/page-header';
import { ScreenName } from '../../../Constant/Screen/Screen';
import AddGeneralMaster from '../../../Form/master/general-master/add-general-master';
import { useModal } from '../../../shared/modal-views/use-modal';
import useAlertController from '../../../Hooks/use-alert-controller';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { useColumn } from '../../../Hooks/use-column';
import { useEffect } from 'react';
import ControlledTable from '../../../Component/ui/table/custom-table';
import { TableClass } from '../../../Constant/Classes/Classes';

function GeneralMaster() {
  const dispatch = useDispatch()
  const reduxGeneral = useSelector(state => state.GeneralMasterReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useAlertController();
  const [loading, setLoading] = useState(false)

  const loadData = (type) => {
    var json = reduxGeneral?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setLoading(true)
    HitApi(json, searchGeneral).then((result) => {
      if (result?.success !== false) {
        CompileGeneralMaster(result).then((compiledData) => {
          dispatch(setGeneralMasterData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements,
          }));
        });
      } else {
        dispatch(setGeneralMasterData([]));
      }
      setLoading(false)
    });
  }

  const columns = useMemo(() => GetGeneralMasterColumns(openModal, closeModal, loadData, showCustomAlert))
  const { visibleColumns } = useColumn(columns);

  useEffect(() => {
    if (reduxGeneral?.doc === null) {
      loadData('init')
    }


  }, [])

  return (
    <div>
      <PageHeader screen={ScreenName?.generalMaster} metaTitle={'General Master'} btnText={'Add General'} children={<AddGeneralMaster closeModal={closeModal} ApiHit={loadData} />} title={'Add General'} customSize={400} />

      <ControlledTable
        screen={ScreenName?.generalMaster}
        variant="modern"
        isLoading={loading}
        showLoadingText={true}
        data={reduxGeneral?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
        json={reduxGeneral?.searchJson}
        setAction={setGeneralMasterData}
        ApiHit={loadData}
      />
    </div>
  )
}

export default GeneralMaster