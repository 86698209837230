import { useState } from 'react';
import CryptoJS from 'crypto-js';

// Custom Hook for MD5 Encoding
const useMd5 = () => {
  const [encoded, setEncoded] = useState('');

  // Function to encode a string to MD5
  const encodeMd5 = (value) => {
    const hash = CryptoJS.MD5(value).toString();

    console.log('hash', hash);

    setEncoded(hash);
  };

  // Return the encoded value and the encode function
  return { encoded, encodeMd5 };
};

export default useMd5;
