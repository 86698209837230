import React, { useState } from 'react';
import { renderButtonClass } from './ButtonRenderer';
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const PermissionComponent = ({ permissionsData }) => {
  const [data, setData] = useState(permissionsData);
  const [showChildren, setShowChildren] = useState({}); // Track visibility of child items

  const toggleAllowed = (permission, type) => {
    if (permission?.[type]) {
      permission[type].allowed = !permission[type].allowed;
    }
  };

  const handleParentPermissionToggle = (parentIndex, action) => {
    const updatedData = [...data];
    const parentPermission = updatedData[parentIndex]?.permission[0];

    if (!parentPermission || !parentPermission[action]) return; // Ensure parentPermission and action exist

    toggleAllowed(parentPermission, action);

    if (action === 'read') {
      if (!parentPermission.read.allowed) {
        toggleAllowed(parentPermission, 'write');
        toggleAllowed(parentPermission, 'delete');

        // Reset all child permissions when parent read is false
        updatedData[parentIndex]?.child?.forEach((child) => {
          const childPermission = child.permission[0];
          ['read', 'write', 'delete'].forEach((type) => {
            toggleAllowed(childPermission, type);
          });
        });
      }
    } else if (action === 'write' && parentPermission.read?.allowed) {
      if (!parentPermission.write.allowed) {
        toggleAllowed(parentPermission, 'delete');

        // Reset child write and delete when parent write is false
        updatedData[parentIndex]?.child?.forEach((child) => {
          const childPermission = child.permission[0];
          ['write', 'delete'].forEach((type) => {
            toggleAllowed(childPermission, type);
          });
        });
      }
    }

    setData(updatedData);
  };

  const handleChildPermissionToggle = (parentIndex, childIndex, action) => {
    const updatedData = [...data];
    const parentPermission = updatedData[parentIndex]?.permission[0];
    const childPermission = updatedData[parentIndex]?.child?.[childIndex]?.permission[0];

    if (!parentPermission || !childPermission || !childPermission[action]) return; // Ensure permissions exist

    toggleAllowed(childPermission, action);

    if (action === 'read') {
      if (!childPermission.read.allowed) {
        toggleAllowed(childPermission, 'write');
        toggleAllowed(childPermission, 'delete');
      }
    } else if (action === 'write' && childPermission.read?.allowed && parentPermission.write?.allowed) {
      if (!childPermission.write.allowed) {
        toggleAllowed(childPermission, 'delete');
      }
    }

    setData(updatedData);
  };

  const toggleChildrenVisibility = (parentIndex) => {
    setShowChildren((prevState) => ({
      ...prevState,
      [parentIndex]: !prevState[parentIndex],
    }));
  };

  return (
    <div className="p-8">
      {data.map((parent, parentIndex) => (
        <div key={parentIndex} className="mb-8">
          <div className="flex items-center justify-between space-x-4">
            <strong className="bg-gray-200 text-black font-medium py-3 px-4 rounded-md w-40 flex justify-between items-center" onClick={() => toggleChildrenVisibility(parentIndex)}>
              {parent.value}
              {showChildren[parentIndex] ? <FaChevronDown /> : <FaChevronRight />}
            </strong>
            <div className="flex space-x-2 ">
              <button
                type="button"
                onClick={() => handleParentPermissionToggle(parentIndex, 'read')}
                className={renderButtonClass(parent.permission[0]?.read?.allowed, true, 'read')}
              >
                Read
              </button>
              <button
                type="button"
                onClick={() => handleParentPermissionToggle(parentIndex, 'write')}
                className={renderButtonClass(parent.permission[0]?.write?.allowed, parent.permission[0]?.read?.allowed, 'write')}
                disabled={!parent.permission[0]?.read?.allowed}
              >
                Write
              </button>
              {/* Conditionally render Delete button and manage space */}
              {parent.permission[0]?.delete && (
                <button
                  type="button"
                  onClick={() => handleParentPermissionToggle(parentIndex, 'delete')}
                  className={renderButtonClass(parent.permission[0]?.delete?.allowed, parent.permission[0]?.read?.allowed && parent.permission[0]?.write?.allowed, 'delete')}
                  disabled={!parent.permission[0]?.read?.allowed || !parent.permission[0]?.write?.allowed}
                >
                  Delete
                </button>
              )}
            </div>
          </div>

          {showChildren[parentIndex] &&
            parent.child.map((child, childIndex) => (
              <div key={childIndex} className="flex items-center justify-between space-x-4 pl-4">
                <strong>{child.value}</strong>
                <div className="flex space-x-2">
                  <button
                    type="button"
                    onClick={() => handleChildPermissionToggle(parentIndex, childIndex, 'read')}
                    className={renderButtonClass(child.permission[0]?.read?.allowed, parent.permission[0]?.read?.allowed, 'read')}
                    disabled={!parent.permission[0]?.read?.allowed}
                  >
                    Read
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChildPermissionToggle(parentIndex, childIndex, 'write')}
                    className={renderButtonClass(child.permission[0]?.write?.allowed, child.permission[0]?.read?.allowed && parent.permission[0]?.write?.allowed, 'write')}
                    disabled={!child.permission[0]?.read?.allowed || !parent.permission[0]?.write?.allowed}
                  >
                    Write
                  </button>
                  {/* Conditionally render Delete button and manage space */}
                  {child.permission[0]?.delete && (
                    <button
                      type="button"
                      onClick={() => handleChildPermissionToggle(parentIndex, childIndex, 'delete')}
                      className={renderButtonClass(child.permission[0]?.delete?.allowed, child.permission[0]?.read?.allowed && child.permission[0]?.write?.allowed && parent.permission[0]?.delete?.allowed, 'delete')}
                      disabled={!child.permission[0]?.read?.allowed || !child.permission[0]?.write?.allowed || !parent.permission[0]?.delete?.allowed}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default PermissionComponent;
