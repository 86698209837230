import { iconSize } from "../iconSize";

export default function ZoneMasterIcon({
  strokeWidth,
  ...props
}) {
  return (
    <svg
      id="fi_10328038"
      enableBackground="new 0 0 369.634 369.634"
      style={
        { height: iconSize.size, width: iconSize.size }
      }
      viewBox="0 0 369.634 369.634"

      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g id="Layer_2_15_">
          <g id="Layer_1-2_15_">
            <path d="m77.494 306.87h-10v-243.9h134.89v10h-124.89z" />
            <path d="m102.204 350.82h-57.63c-23.272-.076-42.604-17.973-44.47-41.17v-.38c0-.52-.07-1-.07-1.51s0-.87 0-1.31v-248.87c.007-21.407 17.366-38.755 38.773-38.748 21.397.007 38.741 17.351 38.748 38.748v10.39h-10v-10.39c-.003-15.884-12.881-28.758-28.765-28.756-15.88.003-28.753 12.876-28.756 28.756v248.86.72.4 1 .38c1.476 17.989 16.49 31.847 34.54 31.88h57.63z" />
            <path d="m369.634 350.82h-246.1v-10h236.1v-267.85h-37v-10h47z" />
            <path d="m237.614 62.97h1.77v10h-1.77z" />
            <path d="m.184 310.52c0-.42-.07-.86-.1-1.28s-.07-1-.08-1.51 0-.85 0-1.28v-.22c.061-21.407 17.463-38.711 38.87-38.65s38.711 17.463 38.65 38.87h-10c.055-15.892-12.783-28.82-28.675-28.875s-28.819 12.783-28.875 28.675v.19.77.34.86.11c0 .34 0 .66.08 1z" />
            <path d="m243.424 167.75h-49.18c-7.975-.023-14.421-6.506-14.399-14.481.008-2.82.842-5.577 2.399-7.929l20.73-31.32 8.34 5.52-20.77 31.28c-1.353 2.045-.792 4.8 1.253 6.153.726.48 1.577.737 2.447.737h49.18z" />
            <path d="m307.184 350.82h-66.35l-21-119.68c-.37-2.127-2.221-3.678-4.38-3.67h-148v-69.72h39.92c1.527.008 2.95-.775 3.76-2.07l58.48-92.71h32.73v10h-27.17l-55.54 88c-2.634 4.208-7.255 6.757-12.22 6.74h-29.92v49.72h138c7.02-.011 13.028 5.034 14.23 11.95l19.51 111.44h45.74l-21.16-106.08c-1.563-7.82 3.51-15.427 11.33-16.99.932-.186 1.88-.28 2.83-.28h21.57v10h-21.57c-2.452.002-4.438 1.992-4.436 4.444 0 .291.029.581.086.866z" />
            <path d="m369.634 227.47h-38.76v-10h28.76v-49.72h-77.95v-10h87.95z" />
            <path d="m235.404 83.06h-2.69v-4.99h-5.01l2-2-1.16-.77 6.67-10.08 2.4 1.58v-3.83h11.07z" />
            <path d="m262.504 195.12c-2.954.015-5.687-1.563-7.15-4.13l-2.62-4.54c-10.52-18.23-23.63-35.69-40.06-53.39-25.541-27.533-23.925-70.558 3.608-96.099 11.716-10.867 26.883-17.261 42.842-18.061 37.509-1.861 69.425 27.037 71.287 64.546.921 18.562-5.795 36.692-18.587 50.174-15.3 16.11-29.44 35.34-42 57.17l-.11.19c-1.47 2.589-4.232 4.175-7.21 4.14zm0-166.29c-.973 0-1.947.023-2.92.07-31.992 1.619-56.614 28.865-54.995 60.857.688 13.593 6.134 26.511 15.385 36.493 17 18.25 30.49 36.31 41.4 55.19l1.12 1.94c11.822-20.423 25.933-39.432 42.06-56.66 22.042-23.243 21.068-59.954-2.175-81.996-10.754-10.198-25.004-15.893-39.825-15.914z" />
            <path d="m262.504 122.19c-19.833 0-35.91-16.077-35.91-35.91s16.077-35.91 35.91-35.91 35.91 16.077 35.91 35.91c-.022 19.823-16.086 35.888-35.91 35.91zm0-61.81c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91c-.016-14.303-11.607-25.894-25.91-25.91z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
