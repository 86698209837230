import React, { useEffect, useState } from 'react'
import CustomButton from '../../../../Component/ui/buttons/custom-button'
import CustomInput from '../../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import { setConfigurationJson } from '../../../../Store/Action/device-master/configuration/configuration-action'
import useValidation from '../../../../Hooks/useValidation'
import { addConfigurationSchema } from '../../../../Utils/validators/device-manager/configuration/add-configuration.schema'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { addConfig, searchConfig, searchGeneral, updateConfig } from '../../../../Constant/Api/Api'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import CustomJsonEditor from '../../../../Component/ui/editor/json-editor'
import { _id } from '../../../../Constant/Common/common-variable'
import { deviceVariable as variable } from '../../../../Constant/variables/device-manager/configuration/configuration.variable'
import useAlertController from '../../../../Hooks/use-alert-controller'
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import cn from '../../../../Utils/class-names'
import { RoundedCard } from '../../../../Constant/Classes/Classes'


export default function AddConfiguration() {
  const dispatch = useDispatch()
  const reduxConfiguration = useSelector(state => state.ConfigurationReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const [input, setInput] = useState({})
  const [output, setOutput] = useState()
  const { errors, validate } = useValidation(addConfigurationSchema);
  const { showCustomAlert } = useAlertController();


  var url = window?.location?.pathname
  var ID = url?.split('/')?.[4]

  useEffect(() => {
    console.log(ID, reduxConfiguration?.apiJson?._id);
    if (ID && !reduxConfiguration?.apiJson?._id) {
      loadDefault(ID)
    }

  }, [reduxConfiguration, ID])

  const loadDefault = (_id) => {
    var searchJson = reduxConfiguration?.searchJson
    var json = reduxConfiguration?.apiJson
    Object.assign(json, { _id: _id })
    HitApi(searchJson, searchConfig).then((result) => {
      if (result?.content?.[0]) {
        console.log('result', result?.content?.[0]);
        var row = result?.content?.[0]
        Object.assign(json, {
          configName: row?.configName,
          config: row?.config,
          useFor : row?.['useFor']
        })
        var selected = reduxSelect?.selected
        dispatch(setSearchableSelectSelectedData([...selected, { label: row?.['useFor'], label: row?.['useFor'], name: 'useFor' }]))
        // useFor
        setInput(result?.content?.[0]?.config?.[0])
        dispatch(setConfigurationJson(json))
      }
    })
  }

  const handleSave = () => {
    var json = reduxConfiguration?.apiJson
    Object.assign(json, { config: [input] })
    const validationErrors = validate(json);
    if (Object.keys(validationErrors).length === 0) {
      if (ID) {
        Object.assign(json, { _id: ID })
        console.log('json', json);
        HitApi(json, updateConfig).then((result) => {
            if (result?.success!==false) {
                showCustomAlert({
                    type: 'success',
                    title: 'Success!',
                    message: result?.message
                });
                
            }
        })
      } else {
        console.log('json', json);
        HitApi(json, addConfig).then((result) => {
            if (result?.success!==false) {
                showCustomAlert({
                    type: 'success',
                    title: 'Success!',
                    message: result?.message
                });
                
            }
        })
      }
    } else {

    }
  }

  const handleCustomChange = (e) => {
    var json = reduxConfiguration?.apiJson
    const { _id, value } = e
    Object.assign(json, { useFor: value })
    dispatch(setConfigurationJson(json))

  }

  const onChange = (value, type) => {

    console.log('value', value);

    try {
      const parsedJson = (value);
      if (type === 'input') {
        setInput(parsedJson);

      }
    } catch (error) {
      console.error('Invalid JSON format:', error.message);
    }
  };

  return (
    <div className={cn(RoundedCard, 'p-8')}>
      <div className='grid grid-cols-4 gap-4'>
        <CustomInput validate={validate} name="configName" label="Configuraiton Name" value={reduxConfiguration?.apiJson?.configName} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationJson} />
        <SearchableSelect validate={validate} name="useFor" label="Use For" api={searchGeneral} getFieldName={'value'} dynamicSearch={{ 'usedBy': 'deviceConfiguration' }} value={reduxConfiguration?.apiJson?.roleName} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationJson} onChange={handleCustomChange} />
      </div>
      <div className='my-5'><CustomJsonEditor json={input} onChange={(value) => onChange(value, 'input')} /></div>
      <div className='my-4'>
        <CustomButton type={'submit'} text={ID ? 'Update Configuration' : 'Save Configuration'} onClick={handleSave} />
      </div>
    </div>
  )
}
