import React from 'react'
import { Tab } from "rizzui";
import Connection from '../../../../WebView/master/configuration-master/tabs-summary/connection/Connection';
import Topic from '../../../../WebView/master/configuration-master/tabs-summary/topic/Topic';


function ConfigMasterTabs({error,validate}) {
    return (
        <Tab>
            <Tab.List>
                <Tab.ListItem>Connection</Tab.ListItem>
                <Tab.ListItem>Topic</Tab.ListItem>
                {/* <Tab.ListItem>Certificates</Tab.ListItem>
                <Tab.ListItem>Batching and Retention</Tab.ListItem> */}

            </Tab.List>
            <Tab.Panels> 
                <Tab.Panel><Connection errors={error} validate={validate}/></Tab.Panel>
                <Tab.Panel><Topic errors={error} validate={validate}/></Tab.Panel>
                {/* <Tab.Panel>Trending panel</Tab.Panel> */}
                {/* <Tab.Panel>Batching and Retention</Tab.Panel> */}

            </Tab.Panels>
        </Tab>
        )
}

export default ConfigMasterTabs