import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAlertController from '../../../Hooks/use-alert-controller';
import { useNavigate } from 'react-router-dom';
import { addSupplierSchema } from '../../../Utils/validators/master/supplier-master/add-supplier.schema';
import useValidation from '../../../Hooks/useValidation';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';
import { Country, State, City } from 'country-state-city';
import { addSupplier, searchGeneral, searchSite, updateSupplier } from '../../../Constant/Api/Api';
import { supplierMasterVariable as variable } from '../../../Constant/variables/master/supplier-master/supplier-master.variable';
import { setSupplierApiJson, setSupplierData } from '../../../Store/Action/master/supplier-master/supplier-master-action';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';




function AddSupplierMaster({ row, closeModal, ApiHit }) {



    const dispatch = useDispatch();
    const reduxSupplier = useSelector(state => state.SupplierMasterReducer);
    const reduxUser = useSelector(state => state.UserReducer);
    const [countries, setCountries] = useState(reduxSupplier?.apiJson?.customerCountry || []);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showCustomAlert } = useAlertController();

    const navigate = useNavigate();


    const { errors, validate } = useValidation(addSupplierSchema);

    const statusOption = [
        { label: 'Active', value: 'Active' },
        { label: 'InActive', value: 'InActive' },
        { label: 'Blocked', value: 'Blocked' },
    ];

    useEffect(() => {

        console.log('row', row);

        if (row?._id) {
            loadDefault(row);
            var json = [{ name: 'supplierGroup', value: row?.supplierGroup, label: row?.supplierGroup }, { name: 'supplierType', value: row?.supplierType, label: row?.supplierType }, { name: 'supplierCountry', value: row?.supplierCountry, label: row?.supplierCountry }, { name: 'supplierCity', value: row?.supplierCity, label: row?.supplierCity }, { name: 'supplierState', value: row?.supplierState, label: row?.supplierState }, { name: 'supplierStatus', value: row?.supplierStatus, label: row?.supplierStatus }, { name: 'siteIds', value: row?.siteIds?.siteName, label: row?.siteIds?.siteName }]
            dispatch(setSearchableSelectSelectedData(json))
            setCountries(row?.supplierCountry)
        }

        const allCountries = Country.getAllCountries().map(country => ({
            label: country.name,
            value: country.name
        }));
        setCountries(allCountries);
    }, [row?._id]);

    const loadDefault = useCallback((row) => {

        var json = { ...reduxSupplier?.apiJson || {} };

        console.log('json', json);

        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        Object.assign(json, { siteIds: row?.siteIds?._id })
        dispatch(setSupplierApiJson(json));
    }, [dispatch, reduxSupplier?.apiJson]);

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = reduxSupplier?.apiJson;

        const validationErrors = validate(json);

        console.log("validationErrors", validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const apiCall = row?._id ? updateSupplier : addSupplier;
            const updatedJson = { ...json, _id: row?._id, status: json?.status || 'Active' };

            HitApi(updatedJson, apiCall).then((result) => {
                setLoading(false);
                if (result?.status === 200 || result?.status === 201) {
                    if (ApiHit) { ApiHit() }

                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result?.message
                    });
                    navigate('/master/supplier/');
                    handleClose()

                } else if (result?.status === 409) {
                    showCustomAlert({
                        type: 'error',
                        title: 'Error',
                        message: result?.error?.message
                    });
                } else {
                    alert(result.message);
                }
            });
        }
    };

    const handleOnChange = useCallback((e, name) => {
        const { _id, value } = e;

        console.log("e", e);
        const newJson = { [name]: name === 'siteIds' ? _id : value };
        const updatedJson = { ...reduxSupplier?.apiJson, ...newJson };
        dispatch(setSupplierApiJson(updatedJson));
    }, [dispatch, reduxSupplier?.apiJson]);

    const handleCountry = useCallback((e) => {
        const selectedCountryName = e?.value;
        const selectedCountry = Country.getAllCountries().find(country => country.name === selectedCountryName)?.isoCode;

        if (!selectedCountry) {
            console.error("Invalid country selected.");
            return;
        }

        const updatedJson = { ...reduxSupplier?.apiJson, supplierCountry: selectedCountryName };
        dispatch(setSupplierApiJson(updatedJson));

        const states = State.getStatesOfCountry(selectedCountry).map(state => ({
            label: state.name,
            value: state.name
        }));
        setStates(states);
        setCities([]);
    }, [dispatch, reduxSupplier?.apiJson]);

    const handleStateChange = useCallback((e) => {
        const selectedStateName = e?.value;
        const countryISOCode = Country.getAllCountries().find(country => country.name === reduxSupplier?.apiJson?.supplierCountry)?.isoCode;
        const stateISOCode = State.getStatesOfCountry(countryISOCode).find(state => state.name === selectedStateName)?.isoCode;

        if (!stateISOCode) {
            console.error("Invalid state selected.");
            return;
        }

        const updatedJson = { ...reduxSupplier?.apiJson, supplierState: selectedStateName };
        dispatch(setSupplierApiJson(updatedJson));

        const cities = City.getCitiesOfState(countryISOCode, stateISOCode).map(city => ({
            label: city.name,
            value: city.name
        }));
        setCities(cities);
    }, [dispatch, reduxSupplier?.apiJson]);

    const handleCityChange = useCallback((e) => {
        const selectedCity = e?.value;
        const updatedJson = { ...reduxSupplier?.apiJson, supplierCity: selectedCity };
        dispatch(setSupplierApiJson(updatedJson));
    }, [dispatch, reduxSupplier?.apiJson]);


    const handleClose = () => {
        dispatch(setSupplierData(null))
        dispatch(setSupplierApiJson({}))
        if (row?._id) {
            closeModal()
        }
    }

    console.log("reduxSupplier", reduxSupplier);
    return (
        <div>
            <div className='p-10 rounded-xl bg-white mt-10' >
                <form onSubmit={handleSubmit}>
                    <div className="space-y-5 lg:space-y-6">
                        <div className='grid grid-cols-4 gap-4 '>
                            <CustomInput important={true} name="supplierName" label="Supplier Name" value={reduxSupplier?.apiJson?.supplierName} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} name="supplierCode" label="Supplier Code" value={reduxSupplier?.apiJson?.supplierCode} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <SearchableSelect searchable validate={validate} name="supplierGroup" label="Supplier Group" api={searchGeneral} dynamicSearch={{ 'usedBy': 'supplierGroup' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setSupplierApiJson} onChange={(e) => handleOnChange(e, 'supplierGroup')} />
                            <CustomInput important={true} name="supplierContactEmail" label="Supplier Email" value={reduxSupplier?.apiJson?.supplierContactEmail} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <SearchableSelect searchable validate={validate} name="supplierType" label="Supplier Type" api={searchGeneral} dynamicSearch={{ 'usedBy': 'supplierType' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setSupplierApiJson} onChange={(e) => handleOnChange(e, 'supplierType')} />
                            <CustomInput important={true} name="supplierAddress1" label="Supplier Add1" value={reduxSupplier?.apiJson?.supplierAddress1} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} name="supplierAddress2" label="Supplier Add2" value={reduxSupplier?.apiJson?.supplierAddress2} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} name="supplierLandmark" label="Supplier Landmark" value={reduxSupplier?.apiJson?.supplierLandmark} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <SearchableSelect searchable name="supplierCountry" label="Supplier Country" defaultOptions={countries} value={reduxSupplier?.apiJson?.supplierCountry} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} onChange={handleCountry} />
                            <SearchableSelect searchable name="supplierState" label="Supplier State" defaultOptions={states} value={reduxSupplier?.apiJson?.supplierState} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} onChange={handleStateChange} />
                            <SearchableSelect searchable name="supplierCity" label="Supplier City" defaultOptions={cities} value={reduxSupplier?.apiJson?.supplierCity} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} onChange={handleCityChange} />
                            <CustomInput important={true} type={"number"} maxLength={6} name="supplierPostCode" label="Supplier PostCode" value={reduxSupplier?.apiJson?.supplierPostCode} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} name="supplierGst" label="Supplier Gst" value={reduxSupplier?.apiJson?.supplierGst} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} type={"number"} name="supplierContactPhone" maxLength={10} label="Supplier Contact" value={reduxSupplier?.apiJson?.supplierContactPhone} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} name="supplierPan" label="Supplier PAN" value={reduxSupplier?.apiJson?.supplierPan} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} name="supplierVat" label="Supplier VAT" value={reduxSupplier?.apiJson?.supplierVat} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <CustomInput important={true} name="supplierTan" label="Supplier TAN" value={reduxSupplier?.apiJson?.supplierTan} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                            <SearchableSelect searchable name="supplierStatus" label="Supplier Status" defaultOptions={statusOption} value={reduxSupplier?.apiJson?.customerCity} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'supplierStatus')} />
                            <SearchableSelect searchable name="siteIds" label="Site" api={searchSite} getFieldName={'siteName'} error={errors} onChange={(e) => handleOnChange(e, 'siteIds')} validate={validate} />
                        </div>
                        <div className='flex gap-3 justify-end'>
                            <CustomButton text={'Back'} variant='flat' onClick={() => { window.location.pathname = 'master/supplier' }} />
                            <CustomButton type={'submit'} text={row?._id ? 'Update' : 'Submit'} loading={loading} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddSupplierMaster