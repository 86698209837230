// import { Text, Tooltip, ActionIcon } from 'rizzui';
// import { HeaderCell } from '../../../Component/ui/table';
// import DeletePopover from '../../../shared/delete-popover';
// import PencilIcon from '../../../Constant/Icons/pencil';
// import { deleteCustomer, deleteMqttConfig } from '../../../Constant/Api/Api';
// import { HitApi } from '../../../Store/Action/Api/ApiAction';
// import { EditScreen } from '../../../shared/edit-screen';
// import AddCustomeMaster from '../../../Form/master/customer-master/add-customer-master';
// import AddConfigurationMaster from '../../../Form/master/configuration-master/add-configuration-master';

// export const getConfigurationMasterColumns = ({ closeModal, openModal }) => [
//     {
//         title: <HeaderCell title="#" />,
//         dataIndex: 'index',
//         key: 'index',
//         width: 30,
//         render: (value) => <Text>{value || '---'}</Text>,
//     },
//     {
//         title: <HeaderCell title="Name" className={'font-extrabold'} />,
//         dataIndex: 'name',
//         key: 'name',
//         width: 150,
//         render: (value) => (
//             <Text className="font-medium text-gray-700">{value || '---'}</Text>
//         ),
//     },
//     {
//         title: <HeaderCell title="Type" className={'font-extrabold'} />,
//         dataIndex: 'type',
//         key: 'type',
//         width: 150,
//         render: (value) => (
//             <Text className="font-medium text-gray-700">{value || '---'}</Text>
//         ),
//     },
//     {
//         title: <HeaderCell title="Description" className={'font-extrabold'} />,
//         dataIndex: 'description',
//         key: 'description',
//         width: 150,
//         render: (value) => (
//             <Text className="font-medium text-gray-700">{value || '---'}</Text>
//         ),
//     },
//     {
//         title: <HeaderCell title="Actions" className={'font-extrabold'} />,
//         dataIndex: 'action',
//         key: 'action',
//         width: 130,
//         render: (_, row) => (
//             <div className="flex items-center gap-3 pe-4">
//                 <Tooltip size="sm" content={'Edit Customer'} placement="top" color="invert">
//                     <a href={'/master/configuration/edit/'+row?._id}>
//                         <PencilIcon className="h-4 w-4" />
//                     </a>
//                 </Tooltip>
//                 <DeletePopover title={`Delete Customer Master`} description={`Are you sure you want to delete this employee?`}
//                     onDelete={() => DeleteItem(row._id)}
//                 />
//             </div>
//         ),
//     },
// ];


// export const DeleteItem = (_id) => {
//     var json = { _id: _id }
//     HitApi(json, deleteMqttConfig).then((Result) => {
//         if(Result?.status === 200){
//             window.location.reload()
//         }
//     })
// }




import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import {  deleteMqttConfig } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import useAlertController from '../../../Hooks/use-alert-controller';
import AddConfigurationMaster from '../../../Form/master/configuration-master/add-configuration-master';
import { useNavigate } from 'react-router-dom';


export const GetConfigurationMasterColumns = (openModal, closeModal, ApiHit) => {
  const { showCustomAlert } = useAlertController();
  const [loadingRows, setLoadingRows] = useState({})
  const navigate = useNavigate();



  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));

    const json = { _id: row?._id };

    console.log('json', row);

    try {
      const result = await HitApi(json, deleteMqttConfig);
      if (result?.success !== false) {
        showCustomAlert({
          type: 'success',
          title: 'Success!',
          message: result.message,
        });
        if (ApiHit) { ApiHit(); }
      } else {
        showCustomAlert({
          type: 'error',
          title: 'Delete Error',
          message: result?.error?.message,
        });
      }
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
    
  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

    return [
      {
        title: <HeaderCell title="#" />,
        dataIndex: 'index',
        key: 'index',
        width: 10,
        render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
      },
      {
        title: <HeaderCell title="Name" className="font-extrabold" />,
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Type" className="font-extrabold" />,
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Description" className="font-extrabold" />,
        dataIndex: 'description',
        key: 'description',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
     
      {
        title: <HeaderCell title="Actions" className="font-extrabold" />,
        dataIndex: 'action',
        key: 'action',
        width: 300,
        render: (_, row) => renderCell(null, row, (
          <TableActions
            screen={'configurationMaster'}
            row={row}
            onEdit={(rowData) => EditScreen(false, false, rowData, 'Edit Configuration Master', AddConfigurationMaster, 1200, ApiHit ,navigate,`/master/configuration/edit/${row._id}`)}
            onDelete={(rowData) => handleDelete(rowData)}
            checkKeys={[]}
          />
        )),
      },
    ];
  
}
