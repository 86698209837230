import React from 'react'
import AddRole from '../../../../Form/role/add-role'

export default function AddRolesAndPermission({closeModal, row, ApiHit}) {
  return (
    <div>
        <AddRole closeModal={closeModal} row={row} ApiHit={ApiHit}/>
    </div>
  )
}
