import React, { useEffect, useState } from 'react'
import CustomInput from '../component/form/custom-input'
import CustomButton from '../component/form/custom-button'
import { useDispatch, useSelector } from 'react-redux'
import { setDevicesApiJson } from '../store/action/devices/devices-action'
import useValidation from '../hook/use-validation'
import { addDeviceSchema } from '../schema/add-device.schema'
import CustomSelect from '../component/form/custom-select'
import { Title } from 'rizzui'
import CustomSwitch from '../../../../Component/ui/switch/custom-switch'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { addDevice, addDeviceProfile, searchApiService, searchBroker, searchCertificate, searchConfig, searchDevice, searchGeneral, searchMapper, updateDevice } from '../../../../Constant/Api/Api'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import { deviceVariable as variable } from '../../../../Constant/variables/device-manager/device/device.variable'
import { setDeviceProfileApiJson } from '../../../../Store/Action/device-master/device-profile/device-profile-action'
import { _id } from '../../../../Constant/Common/common-variable'
import useAlertController from '../../../../Hooks/use-alert-controller'
import OldProfile from './old-profile'
import AddNewDeviceProfile from './add-new-device-profile'


export const Status = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
]

export const Protocol = [
  { label: 'MQTT', value: 'MQTT' },
  { label: 'UDP', value: 'UDP' },
  { label: 'TCP', value: 'TCP' },
  { label: 'HTTP', value: 'HTTP' },
  { label: 'HTTPS', value: 'HTTPS' },
]

export const DeviceType = [
  { label: 'Gateway', value: 'gateway' },
  { label: 'Reader', value: 'Reader' },
]

export const SecureConnectionType = [
  { label: 'TLS', value: 'TLS' },
  { label: 'SSL', value: 'SSL' },
  { label: 'TCP', value: 'TCP' },
]

export default function AddDevice() {

  const dispatch = useDispatch()
  const reduxDevices = useSelector(state => state.DevicesReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxDeviceProfile = useSelector(state => state.DeviceProfileReducer)
  const { showCustomAlert } = useAlertController();
  const { errors, validate } = useValidation(addDeviceSchema);
  const [data, setData] = useState(null)
  const [loading, setLaoding] = useState(false)

  var url = window?.location?.pathname
  var ID = url?.split('/')?.[4]

  useEffect(() => {
    if (ID && !reduxDevices?.apiJson?._id) {
      loadDefault(ID)
    }
  }, [])


  const loadDefault = (_id) => {
    var searchJson = reduxDevices?.searchJson
    var json = reduxDevices?.apiJson
    Object.assign(json, { _id: _id })
    HitApi(searchJson, searchDevice).then((result) => {
      if (result?.content?.[0]) {
        var row = result?.content?.[0]
        handleDataInput(row)
      }
    })
  }

  const handleDataInput = (row) => {
    var json = reduxDevices?.apiJson
    var selected = reduxSelect?.selected
    Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
    var tj = [
      { label: row?.['deviceType'], label: row?.['deviceType'], name: 'deviceType' },
      { label: row?.['protocol'], label: row?.['protocol'], name: 'protocol' },
      { label: row?.['secureConnectionType']?.[0], label: row?.['secureConnectionType']?.[0], name: 'secureConnectionType' },
    ]
    dispatch(setSearchableSelectSelectedData([...selected, ...tj]))
    dispatch(setDevicesApiJson(json))

  }
  // setLaoding(false)

  const handleSubmit = () => {
    var json = reduxDevices?.apiJson
    // setLaoding(true)
    if (ID) {

    } else {
      HitApi(json, addDevice).then((result) => {
        if (result?.data?._id) {
          handleDeviceProfileAdd(result)
        } else {
          setLaoding(false)
        }

      })
    }
  }

  const handleDeviceProfileAdd = (result) => {
    var json = reduxDeviceProfile?.apiJson
    Object.assign(json, { [_id]: result?.data?.[_id] })
    if (json?.[_id]) {
      HitApi(json, addDeviceProfile).then((res) => {
        if (res?.data?._id) {
          handleUpdateDevice(result?.data, res?.data)
        } else {
          setLaoding(false)
        }
      })
    }

  }

  const handleUpdateDevice = (addDeviceResult, deviceProfileResult) => {
    var tempJson = addDeviceResult
    Object.assign(tempJson, { profileId: deviceProfileResult?._id })
    HitApi(tempJson, updateDevice).then((result) => {
      if (result?.data?._id) {
        showCustomAlert({
          type: 'success',
          title: 'Success!',
          message: 'Device Added Successfully'
        });
        setLaoding(false)
      } else {
        setLaoding(false)

      }
    })
  }

  const handleSelect = (e, name, type) => {
    let { label, value, _id } = e
    var json = reduxDevices?.apiJson || {}
    Object.assign(json, { [name]: type ? _id : name === 'secureConnectionType' ? [value] : value })
    dispatch(setDevicesApiJson(json))
  }

  const handleConfigSelect = (e, name) => {
    const { ele } = e; // Destructuring only the necessary property
    const json = reduxDevices?.apiJson; // Shallow copy without initializing deviceProfile upfront

    if (ele && name === 'configName') {
      // Ensure deviceProfile exists on json, and then assign properties
      json.deviceProfile = json.deviceProfile || {};
      Object.assign(json.deviceProfile, {
        profileUsage: ele?.configName,
        profileId: ele?._id,
      });
      setData(ele)
      dispatch(setDeviceProfileApiJson(ele?.config?.[0]))
    } else if (ele && name === 'mapperName') {
      // Ensure deviceProfile exists on json, and then assign properties
      json.deviceProfile = json.deviceProfile || {};
      Object.assign(json.deviceProfile, {
        mappedResultId: ele?._id,
        mapperId: ele?._id,
      });
    } else if (ele && name === 'deviceProfileName') {
      setData(ele)
      dispatch(setDeviceProfileApiJson(ele?.config?.[0]))
    }
    else if (ele && name === 'apiService') {
      // Ensure deviceProfile exists on json, and then assign properties
      json.apiService = json.apiService || {};
      Object.assign(json.apiService, {
        serviceUsage: ele?.serviceName,
        serviceId: ele?._id
      });
    }
  };

  const customOnChange = (e, index) => {
    var json = reduxDeviceProfile?.apiJson
    const { value, name } = e?.target
    Object.assign(json?.capabilities?.[index] || {}, { value: value })
    dispatch(setDeviceProfileApiJson(json))
    console.log('value inner ---', value, name);

  }


  console.log('data', data, reduxDevices?.apiJson);

  return (
    <div className='p-10 bg-white rounded-lg'>
      <form >
        <div className='mb-4'><Title as='h4'>Basic Information</Title></div>
        <div className="grid grid-cols-4 gap-4 ">
          <SearchableSelect name="deviceType" label={'Device Type'} defaultOptions={DeviceType} onChange={(e) => handleSelect(e, 'deviceType')} />
          <CustomInput name="make" label="Make" validate={validate} value={reduxDevices?.apiJson?.make} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="model" label="Model" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="firmwareVersion" label="Firmware Version" value={reduxDevices?.apiJson?.firmwareVersion} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomSwitch name="isCloudConfigurable" label={'Is Cloud Configurable'} position={'top'} value={reduxDevices?.apiJson?.isCloudConfigurable} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          {reduxDevices?.apiJson?.isCloudConfigurable && <SearchableSelect name="protocol" label={'Protocol'} defaultOptions={Protocol} onChange={(e) => handleSelect(e, 'protocol')} />}
          <CustomSwitch name="isSecureConnection" label={'Is Secure Connection'} position={'top'} value={reduxDevices?.apiJson?.isSecureConnection} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          {reduxDevices?.apiJson?.isSecureConnection && <SearchableSelect name="secureConnectionType" label={'Secure Connection Type'} defaultOptions={SecureConnectionType} onChange={(e) => handleSelect(e, 'secureConnectionType')} />}
        </div>
        <div className='mt-8 mb-4'><Title as='h4'>Network Information</Title></div>
        <div className="grid grid-cols-4 gap-4 my-4">
          <CustomInput name="host" label="Host " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="port" label="Port " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="username" label="Username " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="password" label="Password " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomSwitch name="isDeviceMovable" label={'Is Device Movable'} position={'top'} value={reduxDevices?.apiJson?.isDeviceMovable} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          {!reduxDevices?.apiJson?.isDeviceMovable && <CustomInput name="lat" label="Device Location (lat)" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />}
          {!reduxDevices?.apiJson?.isDeviceMovable && <CustomInput name="lng" label="Device Location (lng) " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />}
        </div>
        <div className='mt-8 mb-4'><Title as='h4'>Device Configuraiton</Title></div>
        <div className="grid grid-cols-4 gap-4 my-4">
          <SearchableSelect clearable={false} name="brokerDetailsId" _id={reduxDevices?.apiJson?.brokerDetailsId} label={'Broker'} api={searchBroker} getFieldName={'brokerIp'} onChange={(e) => handleSelect(e, 'brokerDetailsId', true)} />
          {/* <SearchableSelect clearable={false} name="configName" _id={reduxDevices?.apiJson?.deviceProfile?.profileId} label={'Configuration'} api={searchConfig} getFieldName={'configName'} onChange={(e) => handleConfigSelect(e, 'configName')} /> */}
          <SearchableSelect clearable={false} name="certificateId" _id={reduxDevices?.apiJson?.certificateId} label={'Certificate'} api={searchCertificate} getFieldName={'certificateName'} onChange={(e) => handleSelect(e, 'certificateId', true)} />
          <SearchableSelect clearable={false} name="serviceName" _id={reduxDevices?.apiJson?.apiService?.serviceId} label={'Api Service'} api={searchApiService} getFieldName={'serviceName'} onChange={(e) => handleConfigSelect(e, 'apiService')} />
          <SearchableSelect name="mapperName" _id={reduxDevices?.apiJson?.deviceProfile?.mapperId} label={'Mapper'} api={searchMapper} dynamicSearch={{ 'mapperName': { $ne: null } }} getFieldName={'mapperName'} onChange={(e) => handleConfigSelect(e, 'mapperName')} />
        </div>
        <div className='mt-8 mb-4'><Title as='h4'>Device Profile {reduxDevices?.apiJson?.profileId && 'delete'} </Title></div>
        {reduxDevices?.apiJson?.profileId?<OldProfile/>:<AddNewDeviceProfile/>}
        {/* <div className="grid grid-cols-1 gap-4 my-4">
          <SearchableSelect name="configName" _id={reduxDevices?.apiJson?.deviceProfile?.profileId} label={'Device Profile'} api={searchConfig} getFieldName={'configName'} reduxState={reduxDeviceProfile?.apiJson} setAction={setDeviceProfileApiJson} onChange={(e) => handleConfigSelect(e, 'configName')} setData={setData} />
          <div className='grid grid-cols-4 gap-4'>
            {data?.config?.[0]?.capabilities?.map((ele, index) => {
              return <div>
                <CustomInput userMode name={ele?.name} label={ele?.name} parent={reduxDeviceProfile?.apiJson?.capabilities?.[index]} className={'capitalize'} reduxState={reduxDeviceProfile?.apiJson} setAction={setDeviceProfileApiJson}  onChange={(e) => customOnChange(e, index)} />
              </div>
            })}
          </div>
        </div> */}

        <div className='flex gap-3 justify-end'>
          <CustomButton text={'Cancel'} variant='flat' className={''} />
          <CustomButton type={'submit'} className={''} text={'Submit'} onClick={handleSubmit} loading={loading} />
          {/* <CustomButton type={'submit'} className={''} text={'reduxDeviceProfile'} onClick={()=>handleDeviceProfileAdd()} /> */}
        </div>
      </form>

    </div>
  )
}
