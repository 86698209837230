import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import { addLocation,  updateLocation } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { locationMasterVariable as variable } from '../../../Constant/variables/master/location-master/location-master.variable';
import { useState } from 'react';
 import { locationMasterSchema } from '../../../Utils/validators/master/location-master/location-master-scheema';
import { setLocationMasterApiJson } from '../../../Store/Action/master/location-master/location-master-action';
import useAlertController from '../../../Hooks/use-alert-controller';


export default function AddLocationMaster({ row, closeModal, ApiHit }) {
    const reduxLocation = useSelector(state => state.LocationMasterReducer)
    var dispatch = useDispatch()
    const { errors, validate } = useValidation(locationMasterSchema);
    const { showCustomAlert } = useAlertController();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (row?._id) {
            loadDefault(row)
        }
    }, [])

    
    const loadDefault = (row) => {
        var json = reduxLocation?.apiJson
        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        
        dispatch(setLocationMasterApiJson(json))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = reduxLocation?.apiJson
        const validationErrors = validate(json);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true)
            if (row?._id) {
                Object.assign(json, { _id: row?._id ,status :row?.status || 'active'})

                HitApi(json, updateLocation).then((result) => {
                    if (result?.success !== false) {
                        setLoading(false)
                        showCustomAlert({
                            type: 'success',
                            title: 'Success!',
                            message: 'Location Details Updated Successfully',
                        });
                        if (ApiHit) { ApiHit() }
                        handleClose()
                    }
                })
            } else {
                Object.assign(json, { _id: row?._id ,status :row?.status || 'active'})
                HitApi(json, addLocation).then((result) => {
                    console.log("result", result);
                    if (result?.success !== false) {
                        setLoading(false)
                        showCustomAlert({
                            type: 'success',
                            title: 'Success!',
                            message: 'Location Details Added Successfully',
                        });
                        if (ApiHit) { ApiHit() }
                        handleClose()
                    }
                })
            }
        } else {

        }
    };

    const handleClose = () => {
        closeModal()
        dispatch(setLocationMasterApiJson({}))
    }

    return (
        <div className='p-10'>
            <form onSubmit={handleSubmit}>
                <div className="space-y-5 lg:space-y-6">
                    <CustomInput important={true} name="value" label="Location Name" value={reduxLocation?.apiJson?.value} error={errors} reduxState={reduxLocation?.apiJson} setAction={setLocationMasterApiJson} />
                    <div className='flex gap-3 justify-end'>
                        <CustomButton text={'Cancel'} variant='flat' onClick={closeModal} />
                        <CustomButton type={'submit'} className={''} text={row?._id ? 'Update' : 'Submit'} loading={loading} />
                    </div>
                </div>
            </form>
        </div>
    )
}
