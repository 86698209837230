import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { searchLocation } from '../../../Constant/Api/Api';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import PageHeader from '../../../shared/page-header';
import { ScreenName } from '../../../Constant/Screen/Screen';
import { useModal } from '../../../shared/modal-views/use-modal';
import useAlertController from '../../../Hooks/use-alert-controller';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { useColumn } from '../../../Hooks/use-column';
import { useEffect } from 'react';
import ControlledTable from '../../../Component/ui/table/custom-table';
import { TableClass } from '../../../Constant/Classes/Classes';
import { setLocationMasterData } from '../../../Store/Action/master/location-master/location-master-action';
import { CompileLocationMaster } from './promise/location-master-promise';
import { GetLocationMasterColumns } from './location-master-column';
import AddLocationMaster from '../../../Form/master/location-master/add-location-master';

function LocationMaster() {
  const dispatch = useDispatch()
  const reduxLocation = useSelector(state => state.LocationMasterReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useAlertController();
  const [loading, setLoading] = useState(false)

  const loadData = (type) => {
    var json = reduxLocation?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setLoading(true)
    HitApi(json, searchLocation).then((result) => {

      if (result?.success !== false) {
        CompileLocationMaster(result).then((compiledData) => {
          dispatch(setLocationMasterData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements,
          }))
        })
      } else {
        dispatch(setLocationMasterData([]));
      }
      setLoading(false)
    });
  }

  const columns = useMemo(() => GetLocationMasterColumns(openModal, closeModal, loadData, showCustomAlert))
  const { visibleColumns } = useColumn(columns);

  useEffect(() => {
    if (reduxLocation?.doc === null) {
      loadData('init')
    }
  }, [])

  return (
    <div>
      <PageHeader screen={ScreenName?.locationMaster} metaTitle={'Location Master'} btnText={'Add Location'} children={<AddLocationMaster closeModal={closeModal} ApiHit={loadData} />} title={'Add Location'} customSize={400} />
      <ControlledTable
        screen={ScreenName?.locationMaster}
        variant="modern"
        isLoading={loading}
        showLoadingText={true}
        data={reduxLocation?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
        json={reduxLocation?.searchJson}
        setAction={setLocationMasterData}
        ApiHit={loadData}
      />
    </div>
  )
}

export default LocationMaster