import { validationSchema } from "../validationSchema";

// export const addUserSchema = {
//     firstName: validationSchema?.string('First Name is Required').min(1, { message: 'First Name is Required' }),
//     lastName: validationSchema?.string('Last Name is Required').min(1, { message: 'Last Name is Required' }),
//     username: validationSchema?.string('Username is Required').min(1, { message: 'Username Name is Required' }),
//     password: validationSchema?.string('Password is Required').min(1, { message: 'Password is Required' }),
//     gender: validationSchema?.string('Gender is Required').min(1, { message: 'Gender is Required' }),
//     roleName: validationSchema?.string('Role is Required').min(1, { message: 'Role is Required' }),
//     contact: validationSchema?.string('Contact No is Required').min(1, { message: 'Contact No is Required' }),
//     address: validationSchema?.string('Address is Required').min(1, { message: 'Address is Required' }),
//     email: validationSchema.string('Email is Required').regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format'),
//     employeeId: validationSchema?.string('Employee ID  is Required').regex(/^HC.*/i, 'Invalid code format. Must start with HC.')
// };

export const addUserSchema = (isEditMode) => ({
  firstName: validationSchema?.string('First Name is Required').min(1, { message: 'First Name is Required' }),
  lastName: validationSchema?.string('Last Name is Required').min(1, { message: 'Last Name is Required' }),
  username: validationSchema?.string('Username is Required').min(1, { message: 'Username Name is Required' }),
  password: isEditMode 
      ? validationSchema?.string().optional() // Password is optional in edit mode
      : validationSchema?.string('Password is Required').min(1, { message: 'Password is Required' }), // Password is required in add mode
  gender: validationSchema?.string('Gender is Required').min(1, { message: 'Gender is Required' }),
  roleName: validationSchema?.string('Role is Required').min(1, { message: 'Role is Required' }),
  contact: validationSchema?.string('Contact No is Required').min(1, { message: 'Contact No is Required' }),
  address: validationSchema?.string('Address is Required').min(1, { message: 'Address is Required' }),
  email: validationSchema.string('Email is Required').regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format'),
  employeeId: validationSchema?.string('Employee ID  is Required').regex(/^HC.*/i, 'Invalid code format. Must start with HC.')
});