import { iconSize } from "../iconSize";

export default function ReaderMasterIcon({
    strokeWidth,
    ...props
  }) {
    return (
      <svg
      id="fi_8355983"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      style={{height:iconSize.size ,width:iconSize.size}}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="m392.665 64.335h-189.098v-12.43c0-10.424-8.48-18.904-18.905-18.904h-12.204v-25.501c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v25.501h-12.205c-10.424 0-18.904 8.48-18.904 18.904v12.43h-7.014c-12.567 0-22.791 10.224-22.791 22.791v250.091c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-250.091c0-4.296 3.495-7.791 7.791-7.791h273.33c4.296 0 7.791 3.495 7.791 7.791v332.372c0 42.734-34.768 77.502-77.502 77.502h-133.908c-42.734 0-77.502-34.768-77.502-77.502v-47.18c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v47.18c0 51.006 41.496 92.502 92.502 92.502h133.908c51.006 0 92.502-41.496 92.502-92.502v-332.372c0-12.567-10.224-22.791-22.791-22.791zm-251.316 0v-12.43c0-2.152 1.751-3.904 3.904-3.904h39.409c2.153 0 3.905 1.752 3.905 3.904v12.43z" />
        <path d="m306.852 94.764h-172.38c-4.143 0-7.5 3.357-7.5 7.5v296.786c0 4.143 3.357 7.5 7.5 7.5h243.057c4.143 0 7.5-3.357 7.5-7.5v-296.786c0-4.143-3.357-7.5-7.5-7.5h-35.579c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h28.079v281.786h-228.057v-281.786h164.88c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m193.653 424.708c-6.533 0-11.849 5.314-11.849 11.848v17.73c0 6.533 5.315 11.848 11.849 11.848h17.73c6.533 0 11.848-5.314 11.848-11.848v-17.73c0-6.533-5.314-11.848-11.848-11.848zm14.578 26.426h-11.427v-11.426h11.427z" />
        <circle cx={253.93} cy={445.42} r={7.5} />
        <circle cx={288.31} cy={445.42} r={7.5} />
        <circle cx={322.689} cy={445.42} r={7.5} />
        <path d="m321.518 318.481c1.919 0 3.839-.732 5.304-2.196 35.998-35.999 35.998-94.571 0-130.57-2.93-2.928-7.678-2.928-10.607 0-2.929 2.93-2.929 7.678 0 10.607 14.58 14.579 22.609 33.998 22.609 54.678s-8.029 40.099-22.609 54.678c-2.929 2.93-2.929 7.678 0 10.607 1.464 1.464 3.384 2.196 5.303 2.196z" />
        <path d="m295.001 295.071c1.465 1.464 3.385 2.196 5.304 2.196s3.839-.732 5.304-2.196c11.771-11.772 18.255-27.424 18.255-44.071 0-16.648-6.483-32.3-18.256-44.071-2.928-2.928-7.677-2.929-10.606.001-2.929 2.929-2.929 7.678.001 10.606 8.938 8.938 13.861 20.822 13.861 33.464 0 12.641-4.923 24.525-13.862 33.464-2.93 2.93-2.93 7.678-.001 10.607z" />
        <path d="m195.786 196.322c2.929-2.93 2.929-7.678 0-10.607-2.93-2.928-7.678-2.928-10.607 0-35.998 35.999-35.998 94.571 0 130.57 1.465 1.464 3.385 2.196 5.304 2.196s3.839-.732 5.304-2.196c2.929-2.93 2.929-7.678 0-10.607-14.58-14.579-22.609-33.998-22.609-54.678s8.028-40.099 22.608-54.678z" />
        <path d="m211.695 297.268c1.919 0 3.839-.732 5.304-2.196 2.929-2.93 2.929-7.678 0-10.607-8.939-8.938-13.862-20.823-13.862-33.464 0-12.642 4.923-24.525 13.861-33.464 2.93-2.929 2.93-7.678.001-10.606s-7.678-2.93-10.606-.001c-11.773 11.77-18.256 27.422-18.256 44.07 0 16.647 6.483 32.299 18.255 44.071 1.464 1.464 3.384 2.197 5.303 2.197z" />
        <path d="m225.673 249.747c0 16.723 13.604 30.327 30.327 30.327s30.327-13.604 30.327-30.327c0-16.722-13.604-30.326-30.327-30.326s-30.327 13.604-30.327 30.326zm45.654 0c0 8.451-6.876 15.327-15.327 15.327s-15.327-6.876-15.327-15.327 6.876-15.326 15.327-15.326 15.327 6.875 15.327 15.326z" />
      </g>
    </svg>
    );
  }
  