// import React, { useEffect, useMemo, useState } from 'react'
// import { getSupplierMasterColumns } from './supplier-column';
// import ControlledTable from '../../../Component/ControlledTable/ControlledTable'
// import { useModal } from '../../../shared/modal-views/use-modal';
// import { useColumn } from '../../../Hooks/use-column';
// import PageHeader from '../../../shared/page-header';
// import { routes } from '../../../config/routes';
// import { TableClass } from '../../../Constant/Classes/Classes';
// import { useDispatch, useSelector } from 'react-redux';
// import { HitApi } from '../../../Store/Action/Api/ApiAction';
// import { searchSupplier } from '../../../Constant/Api/Api';
// import { CompileSupplierMaster } from './promiss/supplier-master.promiss';
// import { setSupplierData } from '../../../Store/Action/master/supplier-master/supplier-master-action';


// export default function SupplierMaster() {
//   const dispatch = useDispatch()
//   const reduxSupplier = useSelector(state=>state.SupplierMasterReducer)
//   const { openModal, closeModal } = useModal();
//   const [loading ,setLoading] = useState(false)
//   const columns = useMemo(() => getSupplierMasterColumns({ openModal, closeModal ,loading ,setLoading }))
//   const { visibleColumns } = useColumn(columns);


//   useEffect(() => {
//     if(reduxSupplier?.doc === null){
//       loadData()
//     }



//   }, [])

//   const loadData = () => {
//     var json = reduxSupplier?.searchJson
//     HitApi(json, searchSupplier).then((result) => {
//       if(result){

//         CompileSupplierMaster(result).then((CompiledData)=>{
//           dispatch(setSupplierData(CompiledData))
//         })
//       }
//     })
//   }

//   return (
//     <div>
//       <PageHeader btnText={'Create'} href={routes?.panel?.master?.createSupplier} disbleExport />
//       <ControlledTable
//         variant="modern"
//         isLoading={false}
//         showLoadingText={true}
//         data={reduxSupplier?.doc?.content}
//         columns={visibleColumns}
//         className={TableClass}
//       />
//     </div>
//   )
// }



import React, { useMemo, useState } from 'react'
import PageHeader from '../../../shared/page-header'
import { ScreenName } from '../../../Constant/Screen/Screen'
import { routes } from '../../../config/routes'
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../../shared/modal-views/use-modal';
import useAlertController from '../../../Hooks/use-alert-controller';
import { useEffect } from 'react';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { searchSupplier } from '../../../Constant/Api/Api';
import { CompileSupplierMaster } from './promiss/supplier-master.promiss';
import { setSupplierData } from '../../../Store/Action/master/supplier-master/supplier-master-action';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import { GetSupllierMasterColumns } from './supplier-column';
import { useColumn } from '../../../Hooks/use-column';
import { TableClass } from '../../../Constant/Classes/Classes';
import ControlledTable from '../../../Component/ui/table/custom-table';






function SupplierMaster() {
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const dispatch = useDispatch()

  const reduxSupplier = useSelector(state=>state.SupplierMasterReducer)
  const { showCustomAlert } = useAlertController();

  useEffect(() => {
    if (reduxSupplier?.doc === null) {
      loadData('init')
    }


  }, [reduxSupplier])

  console.log('reduxSupplier',reduxSupplier);


  const loadData = (type) => {
    var json = reduxSupplier?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setLoading(true)
    HitApi(json, searchSupplier).then((result) => {
      if (result?.success !== false) {
        CompileSupplierMaster(result).then((compiledData) => {
          dispatch(setSupplierData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements,
          }));
        });
      } else {
        dispatch(setSupplierData([]));
      }
      setLoading(false)

    });
  }


  const columns = useMemo(() => GetSupllierMasterColumns(openModal, closeModal, loadData, showCustomAlert))
  const { visibleColumns } = useColumn(columns);



  return (
    <div>
      <PageHeader screen={ScreenName?.supplierMaster} btnText={'Create'} href={routes?.panel?.master?.createSupplier} customSize={1200} closeModal={closeModal} ApiHit={loadData} />
      <ControlledTable
        screen={ScreenName?.supplierMaster}
        variant="modern"
        isLoading={loading}
        showLoadingText={true}
        data={reduxSupplier?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
        json={reduxSupplier?.searchJson}
        setAction={setSupplierData}
        ApiHit={loadData}
      />

    </div>
  )
}

export default SupplierMaster