import React from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { Button, Dropdown } from 'rizzui'

function HeaderSiteDropdown() {
    return (
        <Dropdown >
            <Dropdown.Trigger>
               <div className='bg-white rounded-xl'>
               <Button color='white' as="span" variant="solid">
                    Belgavi  <FaChevronDown className='ml-5' />
                </Button>
               </div>
            </Dropdown.Trigger>
            <Dropdown.Menu>
                <Dropdown.Item>Belgavi one</Dropdown.Item>
                <Dropdown.Item>Belgavi two</Dropdown.Item>
                <Dropdown.Item>Belgavi three</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default HeaderSiteDropdown