// // useValidation.js

// import { useState } from 'react';

// const useValidation = (schema) => {
//   const [errors, setErrors] = useState({});

//   const validate = (values) => {
//     let tempErrors = {};
//     Object.keys(schema).forEach((key) => {
//       const validation = schema[key];
//       const error = validation.validate(values[key]);
//       if (error) {
//         tempErrors[key] = error;
//       }
//     });
//     setErrors(tempErrors);
//     return tempErrors;
//   };

//   return { errors, validate };
// };

// export default useValidation;


import { useState } from 'react';

const useValidation = (schema) => {
  const [errors, setErrors] = useState({});

  const validate = (fieldOrValues) => {
    let tempErrors = { ...errors };

    if (schema) {
      if (typeof fieldOrValues === 'object' && !Array.isArray(fieldOrValues)) {
        // Validate all fields
        Object.keys(schema).forEach((key) => {
          const validation = schema[key];
          const error = validation.validate(fieldOrValues[key]);
          if (error) {
            tempErrors[key] = error;
          } else {
            delete tempErrors[key]; // Remove error if validation passes
          }
        });
      } else {
        // Validate a single field (field name and value are passed as arguments)
        const [field, value] = fieldOrValues;
        console.log('fieldOrValues', fieldOrValues);
        const validation = schema[field];
        const error = validation.validate(value);
        if (error) {
          tempErrors[field] = error;
        } else {
          delete tempErrors[field]; // Remove error if validation passes
        }
      }
    }

    setErrors(tempErrors);
    return tempErrors;
  };

  return { errors, validate };
};

export default useValidation;
