import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setZoneMasterApiJson } from '../../../Store/Action/master/zone-master/zone-master-action';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import { useModal } from '../../../shared/modal-views/use-modal';
import ControlledTable from '../../../Component/ControlledTable/ControlledTable';
import { TableClass } from '../../../Constant/Classes/Classes';
import { getzoneLocationMasterColumns } from '../../../WebView/master/location-master/location-zone/location-zone-column';
import { useColumn } from '../../../Hooks/use-column';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { CompileZoneMaster } from '../../../WebView/master/zone-master/promise/zone-master-promise';
import { addZoneToLocation, searchSite, searchZone } from '../../../Constant/Api/Api';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import useAlertController from '../../../Hooks/use-alert-controller';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';



function AddLocationZone({ row, ApiHit }) {
    const [zoneOptions, setZoneOptions] = useState()
    const reduxZone = useSelector(state => state.ZoneMasterReducer);
    const { showCustomAlert } = useAlertController();


    const { openModal, closeModal } = useModal();
    const [loading, setLoading] = useState(false)
    const reduxLocation = useSelector(state => state.LocationMasterReducer);

    const dispatch = useDispatch()

    let zoneid = row?._id

    const columns = useMemo(() => getzoneLocationMasterColumns({ openModal, closeModal, zoneid }))
    const { visibleColumns } = useColumn(columns);

    useEffect(() => {

        loadData()     
        dispatch(setSearchableSelectSelectedData(null))
    }, [])

    const loadData = () => {
        var json = reduxLocation?.searchJson
        json.page = 1
        json.limit = 1000
        HitApi(json, searchZone).then((result) => {
            if (result) {
                CompileZoneMaster(result).then((CompiledData) => {

                    const x = CompiledData.content?.map(building => ({
                        label: building.value,
                        value: building._id,
                    }));

                    setZoneOptions(x);
                })
            }
        })
    }

    const handleSubmit = () => {
        if (reduxZone?.apiJson?._id) {
            setLoading(true);
            const json = {
                sourceId: row?._id,
                mapTo: reduxZone?.apiJson?._id,
            };
    
            HitApi(json, addZoneToLocation).then((result) => {
                console.log("result", result);
                setLoading(false);
    
                if (result?.status === 200) {
                    closeModal();
                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result.message,
                    });
                    if (ApiHit) {
                        ApiHit();
                    }
                } else if (result?.error?.message?.includes("mapTo already exists")) {
                    closeModal()
                    showCustomAlert({
                        type: 'error',
                        title: 'Error!',
                        message: 'Zone already added to the location',
                    });
                } else {
                    alert(result.message);
                }
            }).catch((error) => {
                setLoading(false);
                console.error("API call error:", error);
                showCustomAlert({
                    type: 'error',
                    title: 'Error!',
                    message: 'An unexpected error occurred. Please try again.',
                });
            });
        }
    };
    


    const handleOnChange = useCallback((e, name) => {
        const { _id, value } = e;


        const newJson = { [name]: _id };

        const updatedJson = { ...reduxLocation?.apiJson, ...newJson };


        dispatch(setZoneMasterApiJson(updatedJson));
    }, [dispatch, reduxLocation?.apiJson]);


    console.log("reduxLocation", reduxLocation);


    return (
        <div className='p-10'>
            <SearchableSelect name="_id" label="Select Zone" api={searchZone} getFieldName={'value'} onChange={(e) => handleOnChange(e, "_id")} />
            <div className='flex gap-3 justify-end mt-3 mb-5'>
                <CustomButton text={'Cancel'} variant='flat' onClick={closeModal} />
                <CustomButton type={'submit'} text={'Submit'} loading={loading} onClick={handleSubmit} />
            </div>
            <ControlledTable
                variant="modern"
                isLoading={false}
                showLoadingText={true}
                data={row?.usedBy}
                columns={visibleColumns}
                className={TableClass}
                disablePagination={true}
            />
            <div className='flex gap-3 justify-end mt-3 mb-5'>
                <CustomButton text={'Close'} variant='flat' onClick={closeModal} />
            </div>

        </div>
    )
}

export default AddLocationZone
