import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import {  deleteLocation } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import useAlertController from '../../../Hooks/use-alert-controller';
import AddLocationMaster from '../../../Form/master/location-master/add-location-master';
import AddLocationZone from '../../../Form/master/location-master/add-location-zone';




export const GetLocationMasterColumns = (openModal, closeModal, ApiHit) => {
  const { showCustomAlert } = useAlertController();
  const [loadingRows, setLoadingRows] = useState({})


  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));

    const json = { _id: row?._id };

    console.log('json', row);

    try {
      const result = await HitApi(json, deleteLocation);
      if (result?.success !== false) {
        showCustomAlert({
          type: 'success',
          title: 'Success!',
          message: 'Location Deleted Successfully',
        });
        if (ApiHit) { ApiHit(); }
      } else {
        showCustomAlert({
          type: 'error',
          title: 'Delete Error',
          message: 'Unable to delete this role. This role is already linked with a user.',
        });
      }
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }

  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
    },
    {
      title: <HeaderCell title="Location Name" className="font-extrabold" />,
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
   {
    title: <HeaderCell title="Zone Added" className={'font-extrabold'} />,
    dataIndex: 'usedBy',
    key: 'usedBy',
    width: 100,
    render: (usedBy) => (
      <Text className="font-medium text-gray-700">
        {usedBy?.length || 0}
      </Text>
    ),
  },
    {
      title: <HeaderCell title="Status" className="font-extrabold" />,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },

    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 300,
      render: (_, row) => renderCell(null, row, (
        <TableActions
          screen={'locationMaster'}
          row={row}
          onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Edit Location Master', AddLocationMaster, 400, ApiHit)}
          onDelete={(rowData) => handleDelete(rowData)}
          onaddSection={(rowData) => EditScreen(openModal, closeModal, rowData, 'Add Zone', AddLocationZone, 800, ApiHit)}
          checkKeys={['usedBy']}
        />
      )),
    },
  ];

}
