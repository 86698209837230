import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import { zoneMasterSchema } from '../../../Utils/validators/master/zone-master/zone-master-scheema';
import { setZoneMasterApiJson } from '../../../Store/Action/master/zone-master/zone-master-action';
import ControlledTable from '../../../Component/ControlledTable/ControlledTable';
import { useColumn } from '../../../Hooks/use-column';
import { TableClass } from '../../../Constant/Classes/Classes';
import { useModal } from '../../../shared/modal-views/use-modal';
import { getBuildingZoneMasterColumns } from '../../../WebView/master/zone-master/zone-building/zone-building-column';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { addBuildingToZone, searchBuilding } from '../../../Constant/Api/Api';
import { CompileBuildingMaster } from '../../../WebView/master/buildings-master/promiss/building-master.promiss';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import useAlertController from '../../../Hooks/use-alert-controller';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';


function AddZoneBuilding({ row ,ApiHit }) {
    const { showCustomAlert } = useAlertController();
    let zoneid = row?._id



    const reduxZone = useSelector(state => state.ZoneMasterReducer);
    const reduxBuilding = useSelector(state => state.ZoneMasterReducer);
    const [error, setEror] = useState('')

    const [loading, setLoading] = useState(false);
    const { errors, validate } = useValidation(zoneMasterSchema);
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModal();
    const columns = useMemo(() => getBuildingZoneMasterColumns({ openModal, closeModal, zoneid ,showCustomAlert ,ApiHit}))
    const { visibleColumns } = useColumn(columns);
    const [buildingOptions, setBuildingOptions] = useState()


    useEffect(() => {

        loadData()
        dispatch(setSearchableSelectSelectedData(null))

    }, [])

    const loadData = () => {

        var json = reduxBuilding?.searchJson
        json.page = 1
        json.limit = 1000
        HitApi(json, searchBuilding).then((result) => {

            if (result) {
                CompileBuildingMaster(result).then((CompiledData) => {

                    const x = CompiledData.content?.map(building => ({
                        label: building.buildingName,
                        value: building._id,
                    }));

                    setBuildingOptions(x);
                })
            }
        })

        
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (reduxZone?.apiJson?._id) {
            setLoading(true);
            const json = {
                sourceId: row?._id,
                mapTo: reduxZone?.apiJson?._id,
            };
    
            HitApi(json, addBuildingToZone)
                .then((result) => {
                    setLoading(false);
    
                    if (result?.status === 200) {
                        closeModal();
                        showCustomAlert({
                            type: 'success',
                            title: 'Success!',
                            message: result.message,
                        });
                        if (ApiHit) {
                            ApiHit();
                        }
                    } else if (result?.error?.message?.includes("mapTo already exists")) {
                        closeModal();
                        showCustomAlert({
                            type: 'error',
                            title: 'Error!',
                            message: 'Building already added to the zone.',
                        });
                    } else {
                        alert(result.message);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("API error:", error);
                    showCustomAlert({
                        type: 'error',
                        title: 'Error!',
                        message: 'An unexpected error occurred. Please try again.',
                    });
                });
        } 
    };
    



    const handleOnChange = useCallback((e, name) => {
        const { _id, value } = e;
        const newJson = { [name]: _id  };
        const updatedJson = { ...reduxZone?.apiJson, ...newJson };
        dispatch(setZoneMasterApiJson(updatedJson));
    }, [dispatch, reduxZone?.apiJson]);



    return (
        <div className='p-10 mb-40'>
            <form onSubmit={handleSubmit}>
            <SearchableSelect name="_id" label="Select Building" api={searchBuilding} getFieldName={'buildingName'}  onChange={(e) => handleOnChange(e,"_id")} />
                <div className='flex gap-3 justify-end mb-5 mt-3'>
                    <CustomButton text={'Cancel'} variant='flat' className={''} onClick={closeModal} />
                    <CustomButton type={'submit'} className={''} text={'Submit'} loading={loading} onClick={handleSubmit}/>
                </div>
                <ControlledTable
                    variant="modern"
                    isLoading={false}
                    showLoadingText={true}
                    data={row?.usedBy}
                    columns={visibleColumns}
                    className={TableClass}
                    disablePagination={true}
                />
                <div className='flex gap-3 justify-end mb-5 mt-3'>
                    <CustomButton text={'Close'} variant='flat' className={''} onClick={closeModal} />
                </div>

            </form>
        </div>
    )
}

export default AddZoneBuilding
