import React, { useEffect, useState } from 'react'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { addTag, searchGeneral, updateTag } from '../../../../Constant/Api/Api'
import { useDispatch, useSelector } from 'react-redux'
import useValidation from '../../../../Hooks/useValidation'
import Vehicle from './vehicle'
import Location from './location'
import WeighingScale from './weighing-scale'
import Reader from './reader'
import { building, location, reader, vehicle, weighing_scale } from '../../../../Constant/Common/common-variable'
import { RoundedCard } from '../../../../Constant/Classes/Classes'
import cn from '../../../../Utils/class-names'
import { getAuthenticatedUser } from '../../../../Storage/Storage'
import Building from './building'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import useAlertController from '../../../../Hooks/use-alert-controller'


export default function AddTag({ row, selectionType }) {
    const dispatch = useDispatch()
    const reduxTag = useSelector(state => state.TagMasterReducer)
    const { errors, validate } = useValidation({});
    const [selected, setSelected] = useState(null)
    const { showCustomAlert } = useAlertController();

    useEffect(() => {

    }, [])

    const handleTypeSelect = (e) => {
        const { label, value, _id } = e
        var json = reduxTag?.apiJson
        Object.assign(json, { tagPlacement: label })
        setSelected(value)
    }


    const handleSumit = () => {
        var user = getAuthenticatedUser()
        console.log('user', user);
        var json = reduxTag?.apiJson
        var checkKeys = {
            "rfidTag": "",
            "readerId": "",
            "status": "active",
            "movementStatus": "",
            "currentLocation": "",
            "opreationStatus": "",
            "tagType": "",
            "tagInfo": "",
            "tagPlacement": "",
            "tagMovementInfo": "",
            "tagMovementTime": "",
            "tagLotNumber": "",
            "tagWeight": "",
            "tagWeightUnit": "",
            "tagWeightInfo": "",
            "createdBy": user?.userId,
            "updatedBy": user?.userId,
            "batchId": "",
            "product_id": "",
            "dispatchTo": "",
            "batchNumber": "",
            "orderId": "",
            "buildingId": "",
            "batching": true,
            "weight": 0,
            "errorMsg": "",
            "isError": false
        }

        Object.keys(checkKeys).forEach(key => {
            json[key] = checkKeys[key] ? checkKeys[key] : (json[key] ? json[key] : "na");
        });

        if (row?._id) {
            Object.assign(json, { _id: row?._id })

            HitApi(json, updateTag).then((result) => {
                if (result?.success !== false) {
                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result?.message
                    });
                    // if (ApiHit) { ApiHit() }
                }
            })
        } else {
            HitApi(json, addTag).then((result) => {
                if (result?.success !== false) {
                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result?.message
                    });
                    // if (ApiHit) { ApiHit() }
                }
            })
        }

    }

    return (
        <div className={cn(RoundedCard)}>
            <div className='grid grid-cols-4 gap-4'>
                <SearchableSelect selectionType={selectionType} validate={validate} name="tagType" label="Tag Type" api={searchGeneral} dynamicSearch={{ 'usedBy': 'tagMaster' }} getFieldName={'value'} onChange={handleTypeSelect} />
            </div>
            <div className='my-4'>{selected === location ? <Location onClick={handleSumit} /> : selected === weighing_scale ? <WeighingScale onClick={handleSumit} /> : selected === reader ? <Reader onClick={handleSumit} /> : selected === vehicle ? <Vehicle onClick={handleSumit} /> : selected === building ? <Building onClick={handleSumit} /> : null}</div>
        </div>
    )
}