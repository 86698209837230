export const _id = '_id'
export const location = 'location'
export const weighing_scale = 'weighing_scale'
export const reader = 'reader'
export const vehicle = 'vehicle'
export const building = 'building'

// Roles and Permission
export const userManagementMaster = 'user management master'
export const rolesAndPermissionMaster = 'roles and permission master'
export const siteMaster = 'site master'
export const buildingsMaster = 'buildings master'
export const zoneMaster = 'zone master'
export const tagMaster = 'tag master'
export const locationMaster = 'location master'
export const productMaster = 'product master'
export const customerMaster = 'customer master'
export const supplierMaster = 'supplier master'
export const inventoryMaster = 'inventory master'
export const vehicleMaster = 'vehicle master'
export const configuraitonMaster = 'configuraiton master'
export const generalMaster = 'general master'
export const mappingMaster = 'mapping master'

// Device
export const readerMaster = 'reader master'
export const readerReplacementMaster = 'reader replacement master'
export const readerHealthMaster = 'reader health master'
export const readerBuildingMappingMaster = 'reader building mapping master'
export const weighingScaleMaster = 'weighing scale master'

// Device Manager
export const deviceManager = 'Device Manager'
export const certificateManager = 'certificate master'
export const brokerManager = 'broker master'
export const configurationDeviceManager = 'configuration'
export const mapperManager = 'mapper'
export const apiServicesManager = 'api services master'
export const devicesManager = 'devices'

// Hand held device
export const handHeldManager = 'Handheld Manager'
export const outboundOrder = 'Outbond Orders'
export const inboundOrder = 'Inbound Orders'
export const holdOrder = 'Hold'
export const unHoldOrder = 'Un Hold'
export const replaceOrder = 'Replace'
export const consumeOrder = 'Consume'


// export const userManagementMaster = 'user management master'


// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'