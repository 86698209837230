export const ProductMasterVariable = {
    productName : 'productName',
    productCode : 'productCode',
    productDescription : 'productDescription',
    productGroup : 'productGroup',
    height : 'height',
    width:'width',
    length : 'length',
    packedWeight : 'packedWeight',
    weight : 'weight',
    buyingCost : 'buyingCost',
    sellingCost : 'sellingCost',
    grade : 'grade',
    captureBatchNo : 'captureBatchNo',
    captureLotNo : 'captureLotNo',
}