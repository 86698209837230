import { iconSize } from "../iconSize";

export default function TagMasterIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
        id="fi_15715128"
        enableBackground="new 0 0 64 64"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        style={{height:iconSize.size, width:iconSize.size}}
      >
        <g>
          <path d="m57.8 0h-51.6c-3.4 0-6.2 2.8-6.2 6.2v51.7c0 3.3 2.8 6.1 6.2 6.1h51.7c3.4 0 6.2-2.8 6.2-6.2v-51.6c-.1-3.4-2.9-6.2-6.3-6.2zm2.1 57.8c0 1.1-.9 2-2 2h-51.7c-1.1 0-2-.9-2-2v-51.6c0-1.1.9-2 2-2h51.7c1.1 0 2 .9 2 2z" />
          <path d="m56.2 13.9c0-3.4-2.8-6.2-6.2-6.2h-36.1c-3.4 0-6.2 2.8-6.2 6.2v36.1c0 3.4 2.8 6.2 6.2 6.2h28.7l13.6-13.6zm-4.1 27.1-11.1 11.1h-27c-1.1 0-2-.9-2-2v-36.2c0-1.1.9-2 2-2h36.1c1.1 0 2 .9 2 2z" />
          <path d="m43.8 15.5h-23.6c-2.6 0-4.7 2.1-4.7 4.7v23.5c0 2.6 2.1 4.7 4.7 4.7h18c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1h-18c-.3 0-.6-.3-.6-.6v-23.4c0-.3.3-.6.6-.6h23.5c.3 0 .6.3.6.6v15.9h-4.1v-9.3c0-1.7-1.4-3.1-3.1-3.1h-10.2c-1.7 0-3.1 1.4-3.1 3.1v10.3c0 1.7 1.4 3.1 3.1 3.1h12.6.8 8.3v-20c-.1-2.6-2.2-4.7-4.8-4.7z" />
          <circle cx={53.7} cy={53.7} r={3.1} />
        </g>
      </svg>
    );
  }
  