import { SET_DEVICE_PROFILE_API_JSON, SET_DEVICE_PROFILE_DATA, SET_DEVICE_PROFILE_SEARCH_JSON } from "../../../Action/device-master/device-profile/device-profile-action"

const initialState = {
    doc: null,
    apiJson: {},
    searchJson: { page: 1, limit: 10, search: {} },
    timestamp: Date.now()
}

const DeviceProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEVICE_PROFILE_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_DEVICE_PROFILE_API_JSON:
            return ({ ...state, apiJson: action.value, timestamp: Date.now() })
        case SET_DEVICE_PROFILE_SEARCH_JSON:
            return ({ ...state, searchJson: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default DeviceProfileReducer;