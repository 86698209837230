import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Title } from 'rizzui';
import Table from './table';
import cn from '../../../Utils/class-names';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import TablePagination from './table-pagination';
import CustomFilter from '../filter/custom-filter';
import Skeleton from 'react-loading-skeleton'; // Import skeleton loader

export default function ControlledTable({
  columns, className, data, ApiHit, screen, isLoading, showLoadingText, disablePagination, json, setAction
}) {
  const dispatch = useDispatch();
  const reduxPagination = useSelector(state => state.PaginationReducer);

  const handlePaginate = (page) => {
    const json = reduxPagination?.doc;
    json.number = page;
    dispatch(setPagination(json));
    if (ApiHit) ApiHit();
  };

  // Helper function to render skeleton rows
  const renderSkeletonRows = (rowCount) => {
    return Array(rowCount).fill(null).map((_, index) => (
      <tr key={index} className="rc-table-row">
        {columns.map((col, colIndex) => (
          <td key={colIndex} className="rc-table-cell">
            <Skeleton width="100%" height={30} />
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="">
      {/* Filter Section */}
      <div className="my-2">
        {screen ? <CustomFilter screen={screen} json={json} setAction={setAction} ApiHit={ApiHit}/> : null}
      </div>

      {/* Table Section */}
      <div className="relative">
        <Table
          data={!isLoading ? data : []} // Pass empty data when loading
          rowKey={(record) => record.index}
          className={cn(className)}
          columns={columns}
          components={{
            body: {
              wrapper: (props) => (
                <tbody {...props}>
                  {/* When loading, show skeleton rows */}
                  {isLoading ? renderSkeletonRows(5) : props.children}
                </tbody>
              ),
            },
          }}
        />
      </div>

      {/* Pagination Section */}
      {!disablePagination && (
        <TablePagination
          current={reduxPagination?.doc?.number}
          total={reduxPagination?.doc?.totalElements}
          pageSize={reduxPagination?.doc?.limit}
          onChange={handlePaginate}
          ApiHit={ApiHit}
        />
      )}
    </div>
  );
}
