export const usedByOption = [
    { label: 'brokerType', value: 'brokerType' },
    { label: 'brokerSecureConnectionType', value: 'brokerSecureConnectionType' },
    { label: 'brokerCertificate', value: 'brokerCertificate' },
    { label: 'device configuration', value: 'deviceConfiguration' },
    { label: 'certificateType', value: 'certificateType' },
    { label: 'keyUsage', value: 'keyUsage' },
    { label: 'extendedKeyUsage', value: 'extendedKeyUsage' },
    { label: 'defaultData', value: 'defaultData' },
    { label: 'status', value: 'status' },
    { label: 'gender', value: 'gender' },
    { label: 'customerGroup', value: 'customerGroup' },
    { label: 'customerType', value: 'customerType' },
    { label: 'supplierGroup', value: 'supplierGroup' },
    { label: 'supplierType', value: 'supplierType' },
    { label: 'supplierType', value: 'supplierType' },
    { label: 'productGroup', value: 'productGroup' },
    { label: 'tagMaster', value: 'tagMaster' },
    { label: 'vehicleType', value: 'vehicleType' },





    

];

// productGroup