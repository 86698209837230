// import React, { useEffect, useMemo, useState } from 'react'
// import PageHeader from '../../../shared/page-header'
// import { useDispatch, useSelector } from 'react-redux'
// import { useModal } from '../../../shared/modal-views/use-modal'
// import { getGeneralMasterColumns } from '../general-master/general-column'
// import AddVehicleMaster from '../../../Form/master/vehicle-master/add-vehcile-master'
// import ControlledTable from '../../../Component/ControlledTable/ControlledTable'
// import { useColumn } from '../../../Hooks/use-column'
// import { TableClass } from '../../../Constant/Classes/Classes'
// import { getVehicleMasterColumns } from './vehicle-column'
// import { searchVehicle } from '../../../Constant/Api/Api'
// import { setVehicleMasterData } from '../../../Store/Action/master/vehicle-master/vehicle-master-action'
// import { CompileVehicleMaster } from './promise/vehicle-master-promise'
// import { HitApi } from '../../../Store/Action/Api/ApiAction'
// import { setPagination } from '../../../Store/Action/Pagination/PaginationAction'





// function Vehiclemaster() {
//   const dispatch = useDispatch()
//   const reduxVehicle = useSelector(state => state.VehicleMasterReducer)
//   const reduxPagination = useSelector(state => state.PaginationReducer)
//   const [loading ,setLoading] = useState(false)

//   const { openModal, closeModal } = useModal();
//   const columns = useMemo(() => getVehicleMasterColumns({ openModal, closeModal ,loading ,setLoading}))
//   const { visibleColumns } = useColumn(columns);

//   useEffect(() => {
//     if(reduxVehicle?.doc === null){
//       loadData('init')
//     }
//   }, [])
//   const loadData = (type) => {
//     var json = reduxVehicle?.searchJson
//     if (type === 'init') {
//       Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit })
//     } else {
//       Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit })
//     }
//     HitApi(json, searchVehicle).then((result) => {
//       if(result){
//         CompileVehicleMaster(result).then((CompiledData)=>{
//           dispatch(setVehicleMasterData(CompiledData))
//           var tp = { limit: json?.limit, totalPages: CompiledData?.totalPages, number: CompiledData?.number, totalElements: CompiledData?.totalElements }
//           dispatch(setPagination(tp))
//         })
//       }
//     })
//   }


//   return (
//     <div>
//       <PageHeader btnText={'Add Vehicle'} children={<AddVehicleMaster closeModal={closeModal} />} title={'Add Vhecile'} customSize={800} />
//       <ControlledTable
//         variant="modern"
//         isLoading={false}
//         showLoadingText={true}
//         data={reduxVehicle?.doc?.content}
//         columns={visibleColumns}
//         className={TableClass}
//         ApitHit={loadData}
//       />
//     </div>
//   )
// }

// export default Vehiclemaster







// import React, { useMemo, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { searchGeneral } from '../../../Constant/Api/Api';
// import { setGeneralMasterData } from '../../../Store/Action/master/general-master/general-master-action';
// import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
// import { CompileGeneralMaster } from './promise/general-master-promise';
// import { GetGeneralMasterColumns, getGeneralMasterColumns } from './general-column';
// import PageHeader from '../../../shared/page-header';
// import { ScreenName } from '../../../Constant/Screen/Screen';
// import AddGeneralMaster from '../../../Form/master/general-master/add-general-master';
// import { useModal } from '../../../shared/modal-views/use-modal';
// import useAlertController from '../../../Hooks/use-alert-controller';
// import { HitApi } from '../../../Store/Action/Api/ApiAction';
// import { useColumn } from '../../../Hooks/use-column';
// import { useEffect } from 'react';
// import ControlledTable from '../../../Component/ui/table/custom-table';
// import { TableClass } from '../../../Constant/Classes/Classes';

import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../../shared/modal-views/use-modal";
import useAlertController from "../../../Hooks/use-alert-controller";
import { useEffect, useState } from "react";
import { HitApi } from "../../../Store/Action/Api/ApiAction";
import { searchVehicle } from "../../../Constant/Api/Api";
import { CompileVehicleMaster } from './promise/vehicle-master-promise'
import { setVehicleMasterData } from '../../../Store/Action/master/vehicle-master/vehicle-master-action'
import { setPagination } from "../../../Store/Action/Pagination/PaginationAction";
import { useMemo } from "react";
import { GetVehicleMasterColumns } from './vehicle-column'
import { useColumn } from "../../../Hooks/use-column";
import PageHeader from "../../../shared/page-header";
import { ScreenName } from "../../../Constant/Screen/Screen";
import AddVehicleMaster from "../../../Form/master/vehicle-master/add-vehcile-master";
import ControlledTable from "../../../Component/ui/table/custom-table";
import { TableClass } from "../../../Constant/Classes/Classes";





function Vehiclemaster() {
  const dispatch = useDispatch()
  // const reduxGeneral = useSelector(state => state.GeneralMasterReducer)
  const reduxVehicle = useSelector(state => state.VehicleMasterReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useAlertController();
  const [loading, setLoading] = useState(false)

  const loadData = (type) => {
    var json = reduxVehicle?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setLoading(true);

    HitApi(json, searchVehicle).then((result) => {
      if (result?.success !== false) {
        CompileVehicleMaster(result).then((compiledData) => {
          dispatch(setVehicleMasterData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements,
          }));
        });
      } else {
        dispatch(setVehicleMasterData([]));
      }
      setLoading(false);

    });
  }

  const columns = useMemo(() => GetVehicleMasterColumns(openModal, closeModal, loadData, showCustomAlert))
  const { visibleColumns } = useColumn(columns);

  useEffect(() => {
    if (reduxVehicle?.doc === null) {
      loadData('init')
    }


  }, [])

  return (
    <div>
      <PageHeader screen={ScreenName?.vehicleMaster} metaTitle={'Vehicle Master'} btnText={'Add Vehicle'} children={<AddVehicleMaster closeModal={closeModal} ApiHit={loadData} />} title={'Add Vehicle'} customSize={800} />
      <ControlledTable
        screen={ScreenName?.vehicleMaster}
        variant="modern"
        isLoading={loading}
        showLoadingText={true}
        data={reduxVehicle?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
        json={reduxVehicle?.searchJson}
        setAction={setVehicleMasterData}
        ApiHit={loadData}
      />
    </div>
  )
}

export default Vehiclemaster