import React, { useEffect } from 'react'
import CustomInput from '../../../../../Component/ui/form/input/custom-input'
import CustomCheckBox from '../../../../../Component/ui/form/checkbox/custom-checkbox'
import CustomSelect from '../../../../../Component/ui/form/select/custom-select'
import { useSelector } from 'react-redux';
import { setConfigurationMasterApiJson } from '../../../../../Store/Action/master/configuration-master/configuration-master-action';
import useValidation from '../../../../../Hooks/useValidation';
import { configurationMasterSchema } from '../../../../../Utils/validators/master/configuration-master/configuration-master-scheema';

function Connection({errors ,validate}) {
    const reduxConfiguration = useSelector(state => state.ConfigurationMasterReducer);

    console.log("reduxConfiguration",reduxConfiguration);

    useEffect(()=>{

    },[reduxConfiguration])
    return (
        <div className=' py-5 bg-white'>
            <div className='grid grid-cols-3 gap-x-4'>
                <CustomInput important={true} name="endpointhostname" label="Host Name" value={reduxConfiguration?.apiJson?.endpointhostname} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate}  />
                <CustomInput type={'number'} important={true} name="endpointhostport" label="Port" value={reduxConfiguration?.apiJson?.endpointhostport} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate} />
                <CustomInput important={true} name="endpointhostprotocol" label="Protocol" value={reduxConfiguration?.apiJson?.endpointhostprotocol} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate} />
            </div>
            <div className='grid grid-cols-3 gap-x-4'>
                <CustomInput important={true} name="additionalclientId" label="Client Id" value={reduxConfiguration?.apiJson?.additionalclientId} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate} />
                <CustomInput type={'number'} important={true} name="additionalalive" label="Keep Alive" value={reduxConfiguration?.apiJson?.additionalalive} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson}  validate={validate} />
                <CustomCheckBox name="additionalcleanSession" label="Clean Session" value={reduxConfiguration?.apiJson?.additionalcleanSession} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate} />
                <CustomCheckBox name="additionaldebug" label="Debug" value={reduxConfiguration?.apiJson?.additionaldebug} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson}  validate={validate} />
                {/* <CustomCheckBox name="additionalbasicAuth" label="Basic Authentication" value={reduxConfiguration?.apiJson?.additionalbasicAuth} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson}  validate={validate} /> */}
            </div>

        </div>
    )
}

export default Connection