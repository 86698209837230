import { iconSize } from "../iconSize";

export default function VehicleMasterIcon({
    strokeWidth,
    ...props
  }) {
    return (
        <svg
    id="fi_846364"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 447.907 447.907"
    style={{
      enableBackground: "new 0 0 447.907 447.907",height:iconSize.size,width:iconSize.size
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g>
        <path d="M432.703,206.904l-30.5-13.2l-25.9-50.4c-7-13.8-21.2-22.4-36.7-22.3h-44.6v-12.6c0-22.8-18.2-41.4-41-41.4h-213.2                         c-22.8,0-40.8,18.7-40.8,41.4v193c-0.2,13.9,10.9,25.3,24.8,25.5c0.1,0,0.2,0,0.4,0h5.3c0,1-0.1,1.4-0.1,2                         c-0.1,28.6,23,51.9,51.6,52c28.6,0.1,51.9-23,52-51.6c0-0.2,0-0.3,0-0.5c0-0.7,0-1-0.1-2h172.3c0,1,0,1.4,0,2                         c-0.1,28.6,23,51.9,51.6,52c28.6,0.1,51.9-23,52-51.6c0-0.2,0-0.3,0-0.5c0-0.7,0-1,0-2h12.9c13.9,0,25.2-11.3,25.2-25.2                         c0-0.1,0-0.2,0-0.4v-71C448.103,220.304,442.103,211.004,432.703,206.904z M82.303,361.004c-17.6,0-31.8-14.2-31.8-31.8                         c0-17.6,14.2-31.8,31.8-31.8s31.8,14.2,31.8,31.8C114.103,346.704,99.803,361.004,82.303,361.004z M275.003,307.104h-145.8                         c-8.4-18.3-26.8-30-46.9-29.9c-20.2-0.1-38.5,11.5-46.9,29.9h-10.2c-3.1,0-5.2-2.4-5.2-5.5v-51.5h255V307.104z M275.003,108.504                         v121.6h-255v-121.6c0-11.7,9.1-21.4,20.8-21.4h213.2c11.7,0.1,21.1,9.7,21,21.3V108.504z M295.003,141.104h44.6                         c7.9-0.1,15.3,4.2,19,11.3l20,38.7h-83.6V141.104z M358.103,361.004c-17.6,0-31.8-14.2-31.8-31.8c0-17.6,14.2-31.8,31.8-31.8                         s31.8,14.2,31.8,31.8C389.903,346.704,375.703,361.004,358.103,361.004z M428.003,301.604c0,3.1-2.1,5.5-5.2,5.5h-17.8                         c-12.1-25.9-42.9-37.1-68.8-25c-11,5.1-19.9,14-25,25h-16.2v-96h98l31.8,14c2,1,3.3,3.2,3.2,5.4V301.604z" />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
    );
  }
  