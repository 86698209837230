import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import { addCustomer, searchGeneral, searchSite, updateCustomer } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { customerMasterVariable as variable } from '../../../Constant/variables/master/customer-master/customer-master.variable';
import { setCustomerMasterApiJson, setCustomerMasterData } from '../../../Store/Action/master/customer-master/customer-master-action';
import { customerlMasterSchema } from '../../../Utils/validators/master/customer-master/customer-master-schema';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import { Country, State, City } from 'country-state-city';
import { useCallback } from 'react';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';
import useAlertController from '../../../Hooks/use-alert-controller';
import { useNavigate } from 'react-router-dom';

export default function AddCustomeMaster({ row , closeModal,ApiHit}) {
    const dispatch = useDispatch();
    const reduxCustomer = useSelector(state => state.CustomerMasterReducer);
    const reduxUser = useSelector(state => state.UserReducer);
    const [countries, setCountries] = useState(reduxCustomer?.apiJson?.customerCountry || []);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showCustomAlert } = useAlertController();
    const navigate = useNavigate(); 

    const { errors, validate } = useValidation(customerlMasterSchema);

    useEffect(() => {
        if (row?._id) {
            loadDefault(row);
            var json = [{ name: 'customerGroup', value: row?.customerGroup, label : row?.customerGroup},{ name: 'customerType', value: row?.customerType ,label : row?.customerType }, { name: 'customerCountry', value: row?.customerCountry ,label : row?.customerCountry }, { name: 'customerCity', value: row?.customerCity ,label : row?.customerCity },  { name: 'customerState', value: row?.customerState ,label : row?.customerState },{ name: 'customerStatus', value: row?.customerStatus ,label : row?.customerStatus },{ name: 'siteIds', value: row?.siteIds?.siteName ,label : row?.siteIds?.siteName }]
            dispatch(setSearchableSelectSelectedData(json))
            setCountries(row?.customerCountry)
        }

        const allCountries = Country.getAllCountries().map(country => ({
            label: country.name,
            value: country.name
        }));
        setCountries(allCountries);
    }, [row?._id]);

    const loadDefault = useCallback((row) => {

        var json = { ...reduxCustomer?.apiJson || {} };

        console.log('json', json);

        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        Object.assign(json, { siteIds: row?.siteIds?._id })
        dispatch(setCustomerMasterApiJson(json));
    }, [dispatch, reduxCustomer?.apiJson]);

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = reduxCustomer?.apiJson;

        const validationErrors = validate(json);

        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const apiCall = row?._id ? updateCustomer : addCustomer;
            const updatedJson = { ...json, _id: row?._id, status: json?.status || 'Active' };

            HitApi(updatedJson, apiCall).then((result) => {
                setLoading(false);
                if (result?.status === 200 || result?.status === 201) {
                    if (ApiHit) { ApiHit() }

                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result?.message
                      });
                      navigate('/master/customer');
                      handleClose()
                     
                    
                } else if (result?.status === 409) {
                    showCustomAlert({
                        type: 'error',
                        title: 'Error',
                        message: result?.error?.message
                      });
                } else {
                    alert(result.message);
                }
            });
        }
    };

    const handleOnChange = useCallback((e, name) => {
        const { _id, value } = e;

        console.log("e",e);
        const newJson = { [name]: name === 'siteIds' ? _id : value };
        const updatedJson = { ...reduxCustomer?.apiJson, ...newJson };
        dispatch(setCustomerMasterApiJson(updatedJson));
    }, [dispatch, reduxCustomer?.apiJson]);

    const handleCountry = useCallback((e) => {
        const selectedCountryName = e?.value;
        const selectedCountry = Country.getAllCountries().find(country => country.name === selectedCountryName)?.isoCode;

        if (!selectedCountry) {
            console.error("Invalid country selected.");
            return;
        }

        const updatedJson = { ...reduxCustomer?.apiJson, customerCountry: selectedCountryName };
        dispatch(setCustomerMasterApiJson(updatedJson));

        const states = State.getStatesOfCountry(selectedCountry).map(state => ({
            label: state.name,
            value: state.name
        }));
        setStates(states);
        setCities([]);
    }, [dispatch, reduxCustomer?.apiJson]);

    const handleStateChange = useCallback((e) => {
        const selectedStateName = e?.value;
        const countryISOCode = Country.getAllCountries().find(country => country.name === reduxCustomer?.apiJson?.customerCountry)?.isoCode;
        const stateISOCode = State.getStatesOfCountry(countryISOCode).find(state => state.name === selectedStateName)?.isoCode;

        if (!stateISOCode) {
            console.error("Invalid state selected.");
            return;
        }

        const updatedJson = { ...reduxCustomer?.apiJson, customerState: selectedStateName };
        dispatch(setCustomerMasterApiJson(updatedJson));

        const cities = City.getCitiesOfState(countryISOCode, stateISOCode).map(city => ({
            label: city.name,
            value: city.name
        }));
        setCities(cities);
    }, [dispatch, reduxCustomer?.apiJson]);

    const handleCityChange = useCallback((e) => {
        const selectedCity = e?.value;
        const updatedJson = { ...reduxCustomer?.apiJson, customerCity: selectedCity };
        dispatch(setCustomerMasterApiJson(updatedJson));
    }, [dispatch, reduxCustomer?.apiJson]);


    const handleClose = () => {

        dispatch(setCustomerMasterData(null))
        dispatch(setCustomerMasterApiJson({}))
     if(row?._id){
        closeModal()
     }
    }

    return (
        <div>
            <div className='p-10 rounded-xl bg-white mt-10' >
                <form onSubmit={handleSubmit}>
                    <div className="space-y-5 lg:space-y-6">
                        <div className='grid grid-cols-4 gap-4 '>
                            <CustomInput important={true} name="customerName" label="Customer Name" value={reduxCustomer?.apiJson?.customerName} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} name="customerCode" label="Customer Code" value={reduxCustomer?.apiJson?.customerCode} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <SearchableSelect searchable validate={validate} name="customerGroup" label="Customer Group" api={searchGeneral} dynamicSearch={{ 'usedBy': 'customerGroup' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setCustomerMasterApiJson} onChange={(e) => handleOnChange(e, 'customerGroup')} />
                            <CustomInput important={true} name="customerEmail" label="Customer Email" value={reduxCustomer?.apiJson?.customerEmail} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <SearchableSelect searchable validate={validate} name="customerType" label="Customer Type" api={searchGeneral} dynamicSearch={{ 'usedBy': 'customerType' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setCustomerMasterApiJson} onChange={(e) => handleOnChange(e, 'customerType')}  />
                            <CustomInput important={true} name="customerAddress1" label="Customer Add1" value={reduxCustomer?.apiJson?.customerAddress1} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} name="customerAddress2" label="Customer Add2" value={reduxCustomer?.apiJson?.customerAddress2} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} name="customerLandmark" label="Customer Landmark" value={reduxCustomer?.apiJson?.customerLandmark} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <SearchableSelect searchable name="customerCountry" label="Customer Country" defaultOptions={countries} value={reduxCustomer?.apiJson?.customerCountry} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate}  onChange={handleCountry} />
                            <SearchableSelect searchable name="customerState" label="Customer State" defaultOptions={states} value={reduxCustomer?.apiJson?.customerState} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate}  onChange={handleStateChange} />
                            <SearchableSelect searchable name="customerCity" label="Customer City" defaultOptions={cities} value={reduxCustomer?.apiJson?.customerCity} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate}  onChange={handleCityChange} />
                            <CustomInput important={true} type={"number"} maxLength={6} name="customerPostCode" label="Customer PostCode" value={reduxCustomer?.apiJson?.customerPostCode} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} name="customerGst" label="Customer Gst" value={reduxCustomer?.apiJson?.customerGst} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} type={"number"} name="customerContact" maxLength={10} label="Customer Contact" value={reduxCustomer?.apiJson?.customerContact} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} name="customerPan" label="Customer PAN" value={reduxCustomer?.apiJson?.customerPan} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} name="customerVat" label="Customer VAT" value={reduxCustomer?.apiJson?.customerVat} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <CustomInput important={true} name="customerTan" label="Customer TAN" value={reduxCustomer?.apiJson?.customerTan} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                            <SearchableSelect name={'customerStatus'} label="Customer Status" placeholder={'Customer Status'} type={'filter'} api={searchGeneral} dynamicSearch={{'usedBy':'status'}} getFieldName={'value'} onChange={(e) => handleOnChange(e, 'customerStatus')}  useCustomDisplay={true}/>
                            {/* <SearchableSelect name="customerStatus" label="Customer Status" defaultOptions={statusOption} value={reduxCustomer?.apiJson?.customerCity} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate}  onChange={(e) => handleOnChange(e, 'customerStatus')}   /> */}

                            <SearchableSelect searchable name="siteIds" label="Site" api={searchSite} getFieldName={'siteName'} error={errors} onChange={(e) => handleOnChange(e, 'siteIds')} validate={validate} />
                        </div>
                        <div className='flex gap-3 justify-end'>
                            <CustomButton text={'Back'} variant='flat' onClick={() => { window.location.pathname = 'master/customer/' }} />
                            <CustomButton type={'submit'} text={row?._id ? 'Update' : 'Submit'} loading={loading} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
