import { addBroker, addBuilding, addCertificate, addConfig, addCustomer, addDevice, addGeneral, addLocation, addMapper, addMqttConfig, addProduct, addReader, addRole, addSite, addSupplier, addTag, addUser, addVehicle, addZone, deleteBroker, deleteBuilding, deleteCertificate, deleteConfig, deleteCustomer, deleteGeneral, deleteLocation, deleteMapper, deleteMqttConfig, deleteProduct, deleteReader, deleteRole, deleteSite, deleteSupplier, deleteTag, deleteUser, deleteVehicle, deleteZone, mapping, removeMapping, searchApiService, searchBroker, searchBuilding, searchCertificate, searchConfig, searchCustomer, searchDevice, searchGeneral, searchLocation, searchMapper, searchMqttConfig, searchProduct, searchReader, searchRole, searchSite, searchSupplier, searchTag, searchUser, searchVehicle, searchZone, updateBroker, updateBuilding, updateCertificate, updateCustomer, updateGeneral, updateLocation, updateMapper, updateMqttConfig, updateProduct, updateReader, updateRole, updateSite, updateSupplier, updateTag, updateUser, updateVehicle, updateZone } from "../../../Constant/Api/Api";
import { apiServicesManager, brokerManager, buildingsMaster, certificateManager, configuraitonMaster, configurationDeviceManager, consumeOrder, customerMaster, deviceManager, devicesManager, generalMaster, handHeldManager, holdOrder, inboundOrder, inventoryMaster, locationMaster, mapperManager, mappingMaster, outboundOrder, productMaster, readerBuildingMappingMaster, readerHealthMaster, readerMaster, readerReplacementMaster, replaceOrder, rolesAndPermissionMaster, siteMaster, supplierMaster, tagMaster, unHoldOrder, userManagementMaster, vehicleMaster, weighingScaleMaster, zoneMaster } from "../../../Constant/Common/common-variable";
export const AllPermissionList = [
    {
        value: "master",
        url: [],
        permission: [
            {
                read: { allowed: false, url: [] },
                write: { allowed: false, url: [] },
                delete: { allowed: false, url: [] },
            }
        ],
        child: [
            {
                value: userManagementMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchUser] },
                        write: { allowed: false, url: [addUser, updateUser] },
                        delete: { allowed: false, url: [deleteUser] },
                    }
                ]
            },
            {
                value: rolesAndPermissionMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchRole] },
                        write: { allowed: false, url: [addRole, updateRole] },
                        delete: { allowed: false, url: [deleteRole] },
                    }
                ]
            },
            {
                value: siteMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchSite] },
                        write: { allowed: false, url: [addSite, updateSite] },
                        delete: { allowed: false, url: [deleteSite] },
                    }
                ]
            }, {
                value: buildingsMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchBuilding] },
                        write: { allowed: false, url: [addBuilding, updateBuilding] },
                        delete: { allowed: false, url: [deleteBuilding] },
                    }
                ]
            }, {
                value: zoneMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchZone] },
                        write: { allowed: false, url: [addZone, updateZone] },
                        delete: { allowed: false, url: [deleteZone] },
                    }
                ]
            }, {
                value: tagMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchTag] },
                        write: { allowed: false, url: [addTag, updateTag] },
                        delete: { allowed: false, url: [deleteTag] },
                    }
                ]
            }, {
                value: locationMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchLocation] },
                        write: { allowed: false, url: [addLocation, updateLocation] },
                        delete: { allowed: false, url: [deleteLocation] },
                    }
                ]
            },
            {
                value: productMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchProduct] },
                        write: { allowed: false, url: [addProduct, updateProduct] },
                        delete: { allowed: false, url: [deleteProduct] },
                    }
                ]
            },
            {
                value: customerMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchCustomer] },
                        write: { allowed: false, url: [addCustomer, updateCustomer] },
                        delete: { allowed: false, url: [deleteCustomer] },
                    }
                ]
            }, {
                value: supplierMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchSupplier] },
                        write: { allowed: false, url: [addSupplier, updateSupplier] },
                        delete: { allowed: false, url: [deleteSupplier] },
                    }
                ]
            }, {
                value: inventoryMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] },
                        delete: { allowed: false, url: [] },
                    }
                ]
            }, {
                value: vehicleMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchVehicle] },
                        write: { allowed: false, url: [addVehicle, updateVehicle] },
                        delete: { allowed: false, url: [deleteVehicle] },
                    }
                ]
            }, {
                value: configuraitonMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchMqttConfig] },
                        write: { allowed: false, url: [addMqttConfig, updateMqttConfig] },
                        delete: { allowed: false, url: [deleteMqttConfig] },
                    }
                ]
            },
            {
                value: generalMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchGeneral] },
                        write: { allowed: false, url: [addGeneral, updateGeneral] },
                        delete: { allowed: false, url: [deleteGeneral] },
                    }
                ]
            }, {
                value: mappingMaster,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchZone, searchBuilding, searchLocation, searchReader] },
                        write: { allowed: false, url: [mapping, mapping] },
                        delete: { allowed: false, url: [removeMapping] },
                    }
                ]
            },
        ]
    },
    // {
    //     value: "Device",
    //     url: [],
    //     permission: [
    //         {
    //             read: { allowed: false, url: [] },
    //             write: { allowed: false, url: [] },
    //             delete: { allowed: false, url: [] },
    //         }
    //     ],
    //     child: [
    //         {
    //             value: readerMaster,
    //             url: [],
    //             permission: [
    //                 {
    //                     read: { allowed: false, url: [searchReader] },
    //                     write: { allowed: false, url: [addReader, updateReader] },
    //                     delete: { allowed: false, url: [] },
    //                 }
    //             ]
    //         }, {
    //             value: readerReplacementMaster,
    //             url: [],
    //             permission: [
    //                 {
    //                     read: { allowed: false, url: [] },
    //                     write: { allowed: false, url: [] },
    //                     delete: { allowed: false, url: [] },
    //                 }
    //             ]
    //         }, {
    //             value: readerHealthMaster,
    //             url: [],
    //             permission: [
    //                 {
    //                     read: { allowed: false, url: [] },
    //                     write: { allowed: false, url: [] },
    //                     delete: { allowed: false, url: [] },
    //                 }
    //             ]
    //         }, {
    //             value: readerBuildingMappingMaster,
    //             url: [],
    //             permission: [
    //                 {
    //                     read: { allowed: false, url: [] },
    //                     write: { allowed: false, url: [] },
    //                     delete: { allowed: false, url: [] },
    //                 }
    //             ]
    //         }, {
    //             value: weighingScaleMaster,
    //             url: [],
    //             permission: [
    //                 {
    //                     read: { allowed: false, url: [] },
    //                     write: { allowed: false, url: [] },
    //                     delete: { allowed: false, url: [] },
    //                 }
    //             ]
    //         },
    //     ]
    // },
    {
        value: handHeldManager,
        url: [],
        permission: [
            {
                read: { allowed: false, url: [] },
                write: { allowed: false, url: [] },
            }
        ],
        child: [
            {
                value: outboundOrder,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] }
                    }
                ]
            }, {
                value: inboundOrder,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] }
                    }
                ]
            }, {
                value: holdOrder,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] }
                    }
                ]
            }, {
                value: unHoldOrder,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] }
                    }
                ]
            }, {
                value: replaceOrder,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] }
                    }
                ]
            }, {
                value: consumeOrder,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] }
                    }
                ]
            },
        ]
    },
    {
        value: deviceManager,
        url: [],
        permission: [
            {
                read: { allowed: false, url: [] },
                write: { allowed: false, url: [] },
                delete: { allowed: false, url: [] },
            }
        ],
        child: [
            {
                value: certificateManager,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchCertificate] },
                        write: { allowed: false, url: [addCertificate, updateCertificate] },
                        delete: { allowed: false, url: [deleteCertificate] },
                    }
                ]
            }, {
                value: brokerManager,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchBroker] },
                        write: { allowed: false, url: [addBroker, updateBroker] },
                        delete: { allowed: false, url: [deleteBroker] },
                    }
                ]
            }, {
                value: configurationDeviceManager,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchConfig] },
                        write: { allowed: false, url: [addConfig] },
                        delete: { allowed: false, url: [deleteConfig] },
                    }
                ]
            }, {
                value: mapperManager,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchMapper] },
                        write: { allowed: false, url: [addMapper, updateMapper] },
                        delete: { allowed: false, url: [deleteMapper] },
                    }
                ]
            }, {
                value: apiServicesManager,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchApiService] },
                        write: { allowed: false, url: [] },
                        delete: { allowed: false, url: [] },
                    }
                ]
            }, {
                value: devicesManager,
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [searchDevice] },
                        write: { allowed: false, url: [addDevice] },
                        delete: { allowed: false, url: [] },
                    }
                ]
            },
        ]
    },
    {
        value: "inbound",
        url: [],
        permission: [
            {
                read: { allowed: false, url: [] },
                write: { allowed: false, url: [] },
                delete: { allowed: false, url: [] },
            }
        ],
        child: [
            {
                value: "inbound order",
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] },
                        delete: { allowed: false, url: [] },
                    }
                ]
            }, {
                value: "web receving",
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] },
                        delete: { allowed: false, url: [] },
                    }
                ]
            },
        ]
    },
    {
        value: "outbound",
        url: [],
        permission: [
            {
                read: { allowed: false, url: [] },
                write: { allowed: false, url: [] },
                delete: { allowed: false, url: [] },
            }
        ],
        child: [
            {
                value: "outbound order",
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] },
                        delete: { allowed: false, url: [] },
                    }
                ]
            },
            {
                value: "web picking",
                url: [],
                permission: [
                    {
                        read: { allowed: false, url: [] },
                        write: { allowed: false, url: [] },
                        delete: { allowed: false, url: [] },
                    }
                ]
            },
        ]
    }
]