import { useState } from 'react'
import { Select } from "rizzui";

const options = [
  { label: 'Apple 🍎', value: 'apple' },
  { label: 'Apple 🍎', value: 'banana' },
  { label: 'Cherry 🍒', value: 'cherry' },
];

export default function App() {
  const [value, setValue] = useState(options[0]);
  return (
    <Select
      label="Select"
      options={options}
      searchable
      value={value}
      onChange={setValue}
      clearable={value !== null}
      onClear={() => setValue(null)}
    />
  );
}