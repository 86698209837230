import React, { useEffect, useState } from 'react'
import { Badge, Select, Text } from 'rizzui'
import cn from '../../../../Utils/class-names'
import { CompileSelectData } from './select-promiss';
import { HitApi } from '../../../../Store/Action/Api/ApiAction';
import { STATUS_CLASSES } from '../../../../Constant/Colors/Color';
import { setSearchableSelectData, setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import CustomLoading from '../../loading/custom-loading';

function renderOptionDisplayValue(option) {
  const lowerCaseValue = option?.label != null ? option?.label?.toString()?.toLowerCase() : option?.label;
  const statusClass = STATUS_CLASSES[lowerCaseValue] || STATUS_CLASSES.default;
  return (
    <div className="flex items-center">
      <Badge color={statusClass.badgeColor} renderAsDot />
      <Text className={`ms-2 font-medium capitalize ${statusClass.textColor}`}>
        {option?.label}
      </Text>
    </div>
  );
}

function renderDefaultDisplay(option) {
  return (
    <div className="flex items-center">
      <Text className="ms-2 capitalize text-gray-800 transition-colors duration-200 ">
        {option?.label || ''}
      </Text>
    </div>
  );
}

export default function SearchableSelect({ api, name, className, dynamicSearch, limit, getFieldName, type, placeholder, disabled, error, onChange, useCustomDisplay, label, reduxState, defaultOptions, onClear, hide, searchable, selectionType, _id, clearable, setData, setAction }) {
  const dispatch = useDispatch()
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const [options, setOptions] = useState(null)
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(null)
  useEffect(() => {
    if (api && options === null && defaultOptions === undefined) {
      loadData();
    } else {
      if (defaultOptions !== null && defaultOptions !== undefined) {  // Checking for both null and undefined
        setOptions(defaultOptions);
      }
    }
  }, [options]);

  const loadData = () => {
    if (api) {
      const json = { page: 1, limit: limit || 30, search: dynamicSearch || {} };
      // console.log('json ----- ', json);

      setLoading(true)
      HitApi(json, api).then((result) => {
        // console.log('result', result);
        if (result?.success !== false) {
          CompileSelectData(result?.content, getFieldName, type).then((CompiledData) => {
            // console.log('CompiledData', CompiledData);
            if (CompiledData) {
              setOptions(CompiledData);
              dispatch(setSearchableSelectData(result?.content));
              setLoading(false)
            }
          });
        } else {
          setLoading(false)
        }
      });
    }
  };
  const handleChange = (e) => {
    const { value, _id, label } = e;

    var temp_selected = reduxSelect?.selected || [];

    var foundIndex = temp_selected?.findIndex((Obj) => Obj?.name === name && Obj?.selectionType === selectionType);

    if (foundIndex !== -1) {
      // If found, replace the old one
      temp_selected[foundIndex] = { name: name, label: label, value: value, selectionType: selectionType };
    } else {
      // If not found, add a new one
      temp_selected = [...temp_selected, { name: name, label: label, value: value, selectionType: selectionType }];
    }
    dispatch(setSearchableSelectSelectedData(temp_selected));
    if (onChange) onChange(e);
  };

  const handleClear = () => {
    var temp_selected = reduxSelect?.selected || [];
    const existingIndex = temp_selected.findIndex(item => item?.name === name && item?.selectionType === selectionType);

    if (existingIndex !== -1) {
      temp_selected.splice(existingIndex, 1); // Remove the item at the found index
      dispatch(setSearchableSelectSelectedData([...temp_selected])); // Update the state
    }
  };

  const GetValue = () => {
    const foundItem = _id 
      ? options?.find(Obj => Obj._id === _id && Obj?.selectionType === selectionType)
      : reduxSelect?.selected?.find(Obj => Obj.name === name && Obj?.selectionType === selectionType);
  
    //  
    return foundItem?.label;
  }
  

  return (
    <div >
      {!hide && <>
        <div className='font-bold mb-2'>{label && label}</div>
        <Select name={name} searchable={searchable || false} clearable={clearable || true} onClear={() => { onClear ? onClear() : handleClear() }}
          options={options || []}
          placeholder={placeholder ? placeholder : `Select ${label || '...'} `}
          className={cn(className, 'bg-white h-10 z-[99999] rounded-md')}
          dropdownClassName="p-2 gap-1 grid z-[99999] capitalize"
          getOptionDisplayValue={(option) =>
            useCustomDisplay ? renderOptionDisplayValue(option) : renderDefaultDisplay(option)
          }
          error={error?.[name]}
          value={GetValue()}
          onChange={handleChange}
        />
        {/* <CustomLoading /> */}
        {disabled && (
          <span className='text-red-500 text-xs tracking-wide'>
            This field cannot be edited
          </span>
        )}
      </>}
    </div>
  )
}
